import { AxiosResponse } from 'axios';
import { ApiServiceBase } from '../api-service-base';
import {
  GetCollectionsArguments,
  CreateCollectionArguments,
  GetCollectionArguments,
  UpdateCollectionArguments,
  DeleteCollectionArguments,
  GetRuleGroupsArguments,
  SaveCollectionRulesDiffArguments,
  GetCollectionsPath,
  ExportCollectionTitlesCSVArguments,
  ImportCollectionTitlesCSVArguments,
} from './types';
import { Collection, RuleGroup } from '../../../../components-bl/Collections/types';
import {
  mapCreateCollectionResponse,
  mapCollectionsResponse,
  mapCollectionResponse,
  mapReportConfiguration,
} from './collectionsMapper';
import { mapRuleGroupResponse } from './ruleGroupMapper';
import { buildQueryParams, downloadResponseFile } from '../../common/utils';

export class Collections extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getCollectionsPath({ shopId, variantId }: GetCollectionsPath): string {
    const queryStringParams = buildQueryParams({ variantId });
    return `${this.serviceBaseUri}/${shopId}/collections${queryStringParams}`;
  }

  private getCollectionsTranslationsPath({ shopId }: GetCollectionsPath): string {
    return `${this.serviceBaseUri}/${shopId}/translations/collections/titles`;
  }

  async getCollections({ shopId, variantId }: GetCollectionsArguments): Promise<Collection[]> {
    const url = `${this.getCollectionsPath({ shopId, variantId })}`;
    const response = await this.httpService.get({ url });

    return mapCollectionsResponse(response);
  }

  async createCollection({
    shopId,
    name,
    placement,
    ruleGroups,
    variantId,
  }: CreateCollectionArguments): Promise<Collection> {
    const requestData = { name, placement, ruleGroups };
    const url = `${this.getCollectionsPath({ shopId, variantId })}`;
    const response = await this.httpService.post({ url, data: requestData });

    return mapCreateCollectionResponse(response);
  }

  async getCollection({
    shopId,
    collectionId,
    variantId,
  }: GetCollectionArguments): Promise<Collection> {
    const queryStringParams = buildQueryParams({ variantId });
    const url = `${this.getCollectionsPath({ shopId })}/${collectionId}${queryStringParams}`;
    const response = await this.httpService.get({ url });

    return mapCollectionResponse(response);
  }

  async updateCollection({
    shopId,
    collectionId,
    body,
    variantId,
  }: UpdateCollectionArguments): Promise<Collection> {
    const queryStringParams = buildQueryParams({ variantId });
    const url = `${this.getCollectionsPath({ shopId })}/${collectionId}${queryStringParams}`;
    const response = await this.httpService.patch({ url, data: body });

    return mapCollectionResponse(response);
  }

  async deleteCollection({
    shopId,
    collectionId,
    variantId,
  }: DeleteCollectionArguments): Promise<Collection[]> {
    const queryStringParams = buildQueryParams({ variantId });
    const url = `${this.getCollectionsPath({ shopId })}/${collectionId}${queryStringParams}`;
    const response = await this.httpService.delete({ url });

    return mapCollectionsResponse(response);
  }

  async getRuleGroups({
    shopId,
    collectionId,
    variantId,
  }: GetRuleGroupsArguments): Promise<RuleGroup[]> {
    const queryStringParams = buildQueryParams({ variantId });
    const url = `${this.getCollectionsPath({ shopId })}/${collectionId}/collection-rules/rule-groups${queryStringParams}`;
    const response = await this.httpService.get({ url });

    return mapRuleGroupResponse(response);
  }

  async saveCollectionRulesDiff({
    shopId,
    collectionId,
    body,
    variantId,
  }: SaveCollectionRulesDiffArguments): Promise<RuleGroup[]> {
    const queryStringParams = buildQueryParams({ variantId });
    const url = `${this.getCollectionsPath({ shopId })}/${collectionId}/collection-rules/rule-groups${queryStringParams}`;
    const response = await this.httpService.patch({ url, data: body });

    return mapRuleGroupResponse(response);
  }

  async exportCollectionTitlesCSV({
    shopId,
    variantId,
  }: ExportCollectionTitlesCSVArguments): Promise<AxiosResponse<string>> {
    const queryStringParams = buildQueryParams({ variantId });
    const url = `${this.getCollectionsTranslationsPath({ shopId })}/export${queryStringParams}`;
    const response = await this.httpService.get({ url });

    downloadResponseFile({ response, defaultName: 'collections_translations.csv' });

    return response;
  }

  async importCollectionTitlesCSV({
    file,
    shopId,
    onUploadProgress,
    cancellationSignal,
    variantId,
  }: ImportCollectionTitlesCSVArguments): Promise<string> {
    const queryStringParams = buildQueryParams({ variantId });
    const url = `${this.getCollectionsTranslationsPath({ shopId })}/import${queryStringParams}`;

    const formData = new FormData();
    formData.append('file', file);

    const response = await this.httpService.post({
      url,
      data: formData,
      requestConfig: {
        onUploadProgress,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        signal: cancellationSignal,
      },
    });

    return mapReportConfiguration(response);
  }
}

export const collectionsService = new Collections();
