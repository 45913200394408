import React from 'react';

import { shouldShowGreenTrunkComponent } from './shouldShowGreenTrunkComponent';

export const GreenTrunkUIComponent = ({ children }) => {
  if (shouldShowGreenTrunkComponent()) {
    return <>{children}</>;
  }
  return <></>;
};
