import React from 'react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy/Typography';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ExportCSVConfirmationDialogWrapperStyled } from './CatalogExplorerPageHeader.styles';

export interface ExportCSVConfirmationDialogProps {
  onClose: () => void;
  onExportCatalog: () => void;
}

export const ExportCSVConfirmationDialog = ({
  onExportCatalog,
  onClose,
}: ExportCSVConfirmationDialogProps): JSX.Element => {
  return (
    <ExportCSVConfirmationDialogWrapperStyled>
      <ConfirmationDialog onCancel={onClose}>
        <ConfirmationDialog.Header>
          <ConfirmationDialog.Title>Export Catalog Feed</ConfirmationDialog.Title>
        </ConfirmationDialog.Header>
        <ConfirmationDialog.Content css={{ paddingBottom: '24px', gap: '15px' }}>
          <Typography type={TypographyType.Paragraph} variant={TypographyVariant.LargeRegular}>
            The export includes only the catalog fields.
            <br />
            AI-generated tags displayed in the table will not be included.
          </Typography>
        </ConfirmationDialog.Content>
        <ConfirmationDialog.Footer>
          <Button variant='primary' onClick={onExportCatalog}>
            Export Catalog
          </Button>
          <Button variant='tertiary' onClick={onClose}>
            Cancel
          </Button>
        </ConfirmationDialog.Footer>
      </ConfirmationDialog>
    </ExportCSVConfirmationDialogWrapperStyled>
  );
};
