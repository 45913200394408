import React from 'react';
import { ICatalogProduct } from 'src/services';
import { CatalogExplorerTable } from '../CatalogExplorerTable';
import { OnPaginationChange, PaginationState, PaginationToolBar } from 'src/components-dummy';
import { ProductsContainerStyled } from './CatalogExplorerProductsView.styles';
import { CatalogExplorerTableSkeleton } from '../CatalogExplorerTable/CatalogExplorerTableSkeleton';
import { DisplayedFieldItem } from '../CatalogExplorerDisplayPreferences/types';
import { DisplayFieldsByType } from '../types';

interface CatalogExplorerProductsViewProps {
  shopId: number;
  catalogProducts?: ICatalogProduct[];
  totalCatalogProducts?: number;
  displayFieldsMap: Record<string, DisplayedFieldItem>;
  isPendingOnFetchProducts: boolean;
  paginationState: PaginationState;
  displayFieldsPreferencesByType: DisplayFieldsByType;
  isLoading: boolean;
  onPaginationChange: OnPaginationChange;
}

export const CatalogExplorerProductsView = React.memo(
  ({
    catalogProducts,
    totalCatalogProducts,
    displayFieldsMap,
    isPendingOnFetchProducts,
    paginationState,
    displayFieldsPreferencesByType,
    isLoading,
    onPaginationChange,
  }: CatalogExplorerProductsViewProps): JSX.Element => {
    if (!catalogProducts || isLoading) {
      return <CatalogExplorerTableSkeleton />;
    }

    return (
      <>
        <ProductsContainerStyled>
          <CatalogExplorerTable
            dataFieldsMapByName={displayFieldsMap}
            catalogProducts={catalogProducts}
            paginationState={paginationState}
            isPendingOnFetchProducts={isPendingOnFetchProducts}
            displayFieldsPreferencesByType={displayFieldsPreferencesByType}
          />
        </ProductsContainerStyled>
        <PaginationToolBar
          skip={paginationState.skip}
          limit={paginationState.limit}
          totalItems={totalCatalogProducts || 0}
          onChange={onPaginationChange}
        />
      </>
    );
  }
);
