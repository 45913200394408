import React, { useCallback, useState } from 'react';
import {
  AvailableIcons,
  EllipsisWithTooltip,
  Icon,
  Tooltip,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import {
  BackIconStyled,
  PageMainTitleRowStyled,
  PageMainTitleStyled,
  PageSubTitleRowStyled,
  PageTitleWrapperStyled,
  PrimaryCatalogStyled,
  SeparatorBulletStyled,
  TitleContextStyled,
  ButtonsSectionStyled,
  InformationIconWrapper,
} from './CatalogExplorerPageHeader.styles';
import { IShopCatalog } from 'src/services';
import { TitleMainStyled } from '../../CatalogManager.styles';
import { FlagIcon } from 'src/components-bl/Lexicon/components/LexiconPageHeader/LexiconPageHeader.styles';
import { MetaLanguage } from 'src/app-state-types';
import { addSeparatorsToNumber } from '../CatalogExplorer.helpers';
import { CatalogExplorerActionsMenu } from './CatalogExplorerActionsMenu';
import { ExportCSVConfirmationDialog } from './ExportCSVConfirmationDialog';
import { CatalogExplorerDisplayPreferences } from '../CatalogExplorerDisplayPreferences/CatalogExplorerDisplayPreferences';
import { DataFieldsByType, DisplayFieldsByType } from '../types';
import { Dispatch } from 'src/components-bl/types';
import { DisplayPreferencesButtonWithPromo } from './DisplayPreferencesButtonWithPromo';

interface CatalogExplorerPageHeaderProps {
  shopId: number;
  locale: string;
  catalog?: IShopCatalog;
  totalCatalogProducts?: number;
  catalogName: string;
  languageMetadata?: MetaLanguage;
  dataFieldsByType: DataFieldsByType;
  displayFieldsPreferencesByType: DisplayFieldsByType;
  isMerchRulesAITagsEnabled: boolean;
  hasCustomLexiconConfig: boolean;
  navigateToCatalogsList: () => void;
  onExportToCSV: () => void;
  dispatch: Dispatch;
}

export const CatalogExplorerPageHeader = React.memo(
  ({
    shopId,
    locale,
    catalog,
    totalCatalogProducts,
    catalogName,
    languageMetadata,
    dataFieldsByType,
    displayFieldsPreferencesByType,
    isMerchRulesAITagsEnabled,
    hasCustomLexiconConfig,
    navigateToCatalogsList,
    onExportToCSV,
    dispatch,
  }: CatalogExplorerPageHeaderProps) => {
    const [showExportCSVModal, setShowExportCSVModal] = useState(false);
    const [showDisplayPreferences, setShowDisplayPreferences] = useState(false);

    const contextPreTitle = 'Catalog Manager > ';
    const mainTitleText = `View ${catalogName}`;
    const fullTitleText = contextPreTitle + mainTitleText;

    const onToggleShowExportCSVModal = useCallback(() => {
      setShowExportCSVModal(prevState => !prevState);
    }, []);

    const onExportToCSVClick = useCallback(() => {
      onExportToCSV();
      onToggleShowExportCSVModal();
    }, []);

    const onDisplayPrefButtonClick = useCallback(() => {
      setShowDisplayPreferences(prevState => !prevState);
    }, []);

    return (
      <PageTitleWrapperStyled>
        {showExportCSVModal && (
          <ExportCSVConfirmationDialog
            onClose={onToggleShowExportCSVModal}
            onExportCatalog={onExportToCSVClick}
          />
        )}
        {showDisplayPreferences && (
          <CatalogExplorerDisplayPreferences
            key={`${shopId}_${locale}_${catalogName}`}
            shopId={shopId}
            locale={locale}
            catalogName={catalogName}
            dataFieldsByType={dataFieldsByType}
            displayFieldsPreferencesByType={displayFieldsPreferencesByType}
            onClose={onDisplayPrefButtonClick}
            dispatch={dispatch}
            isMerchRulesAITagsEnabled={isMerchRulesAITagsEnabled}
          />
        )}
        <PageMainTitleRowStyled>
          <PageMainTitleStyled>
            <BackIconStyled name={AvailableIcons.ArrowRounded} onClick={navigateToCatalogsList} />
            <EllipsisWithTooltip tooltipText={fullTitleText}>
              <TitleMainStyled
                type={TypographyType.Heading}
                variant={TypographyVariant.ExtraSmallBold}
                className='syte-page-title'
              >
                <TitleContextStyled>{contextPreTitle}</TitleContextStyled>
                {mainTitleText}
              </TitleMainStyled>
            </EllipsisWithTooltip>
            {catalog?.isPrimary && (
              <PrimaryCatalogStyled>
                <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                  Primary catalog
                </Typography>
              </PrimaryCatalogStyled>
            )}
          </PageMainTitleStyled>
          <ButtonsSectionStyled>
            <CatalogExplorerActionsMenu onExportToCSV={onToggleShowExportCSVModal} />
            <DisplayPreferencesButtonWithPromo
              onDisplayPrefButtonClick={onDisplayPrefButtonClick}
            />
          </ButtonsSectionStyled>
        </PageMainTitleRowStyled>
        <PageSubTitleRowStyled>
          {totalCatalogProducts && (
            <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
              {addSeparatorsToNumber(totalCatalogProducts)} Items
            </Typography>
          )}

          <SeparatorBulletStyled />
          <>
            {languageMetadata?.iconName && (
              <FlagIcon name={languageMetadata.iconName as AvailableIcons} />
            )}
            {catalog?.defaultLocale && (
              <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
                {languageMetadata?.displayName || ''} (Locale: {catalog?.defaultLocale || ''})
              </Typography>
            )}
            {hasCustomLexiconConfig && (
              <InformationIconWrapper>
                <Tooltip value='Does not reflect lexicon customizations' position='bottom center'>
                  <Icon name={AvailableIcons.InformationV2} />
                </Tooltip>
              </InformationIconWrapper>
            )}
          </>
        </PageSubTitleRowStyled>
      </PageTitleWrapperStyled>
    );
  }
);
