import { createAsyncThunk } from '@reduxjs/toolkit';
import { ShopCatalogVertical } from 'src/services';
import { catalogsListPageActions, editExistingCatalogFormActions } from './CatalogsListPage';

export const CatalogManagerActions = {
  reconnectCatalog: createAsyncThunk(
    'CatalogManager/ReconnectCatalog',
    async (
      {
        shopId,
        id,
        name,
        isPrimary,
        isAugmentedSearchCatalog,
        verticals,
      }: {
        shopId: number;
        id: string;
        name: string;
        isPrimary: boolean;
        isAugmentedSearchCatalog: boolean;
        verticals: ShopCatalogVertical[];
      },
      { dispatch, rejectWithValue }
    ) => {
      try {
        await dispatch(catalogsListPageActions.deleteCatalog({ shopId, id }));

        const connectedCatalog = await dispatch(
          editExistingCatalogFormActions.connectCatalog({
            shopId,
            name,
            isPrimary,
            isAugmentedSearchCatalog,
            verticals,
          })
        );

        return connectedCatalog;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
