import React from 'react';
import { generatePath } from 'react-router';
import { AppRoute, AppSwitch } from 'src/app-routes';
import { collectionNavigationActionMethods } from '../../components-bl';
import { Collections } from './Collections';
import { CreateCollectionContainer } from '../CreateCollection';
import { EditCollectionContainer } from '../EditCollection';
import { CollectionsTranslationFileUploadModal } from '../../components-bl/Collections/CollectionsTranslationFileUploadModal/CollectionsTranslationFileUploadModal';
import type { CollectionsContainerProps } from './types';

export const CollectionsContainer = ({
  permittedRouteMap,
  shopId,
  dispatch,
}: CollectionsContainerProps): JSX.Element => {
  const onCollectionsTranslationsModalClose = () => {
    if (permittedRouteMap.collections && shopId) {
      dispatch(
        collectionNavigationActionMethods.navigateTo({
          navigateTo: generatePath(permittedRouteMap.collections.path, { shopId }),
        })
      );
    }
  };

  return (
    <AppSwitch>
      <AppRoute
        route={permittedRouteMap.collections}
        Component={Collections}
        componentProps={{ permittedRouteMap, shopId, dispatch }}
      />
      <AppRoute
        route={permittedRouteMap.createCollection}
        Component={CreateCollectionContainer}
        componentProps={{ permittedRouteMap, shopId, dispatch, isCreateMode: true }}
      />
      <AppRoute
        route={permittedRouteMap.editCollection}
        Component={EditCollectionContainer}
        componentProps={{ permittedRouteMap, shopId, dispatch }}
        exact={false}
      />
      <AppRoute
        route={permittedRouteMap.collectionsTranslationFileUpload}
        Component={CollectionsTranslationFileUploadModal}
        componentProps={{
          shopId,
          dispatch,
          onCollectionsTranslationsModalClose,
        }}
      />
    </AppSwitch>
  );
};
