import styled from '@emotion/styled';
import { Button, Icon } from 'src/components-dummy';

export const ProductTileStyled = styled.div`
  padding: 16px 24px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 335px;
  background-color: ${({ theme }) => theme.palette.common.white};

  @media (max-width: 1118px) {
    width: 100%;
    min-width: 240px;
  }
`;

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const ParagraphStyled = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const LinkButtonStyled = styled(Button)`
  width: fit-content;
  height: 26px;
  padding: 3px 5px;
  margin-top: auto;
`;

export const IconStyled = styled(Icon)`
  position: relative;
  left: -10px;
`;
