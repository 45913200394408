import styled from '@emotion/styled';

/**
 * Section
 */
export const SectionContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 8px;
`;

export const SectionHeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

/**
 * DataFieldsSelectSection
 */
export const SectionContentStyled = styled.div`
  display: flex;
  padding: 16px 14px 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  background: ${({ theme }) => theme.palette.custom.white};
`;

export const DataFieldSelectSectionHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row-reverse;
  width: 100%;
`;

/**
 * AI Tags section
 */
export const AITagsTryItNowPromo = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.custom['primary-main']};
  color: #004599;

  & > svg:first-of-type {
    margin-right: 6px;

    path {
      stroke: #0073ff;
    }
  }
`;

/**
 * Not Permitted tags
 */
export const AITagsDisabledTitleStyled = styled.div`
  color: ${({ theme }) => theme.palette.custom['gray-30']};
`;

export const AITagsLearnMoreItemStyled = styled.div`
  display: flex;
  padding: 16px 16px 16px 24px;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  cursor: pointer;

  box-shadow:
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px -1px 6px -2px rgba(0, 0, 0, 0.06);

  & > div {
    display: inline-flex;
    gap: 16px;
  }

  & > svg {
    & > path {
      stroke: ${({ theme }) => theme.palette.custom['gray-10']};
    }
  }
`;
