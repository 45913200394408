import React from 'react';
import {
  BubbleContainerStyled,
  BubbleStyled,
  Triangle1Styled,
  Triangle2Styled,
} from './SneakPick.styles';

export const SneakPeak = ({ children }: { children: React.ReactChild }) => {
  return (
    <BubbleContainerStyled>
      <BubbleStyled>
        {children}
        <Triangle1Styled />
        <Triangle2Styled />
      </BubbleStyled>
    </BubbleContainerStyled>
  );
};
