import styled from '@emotion/styled';
import { SearchInput } from 'src/components-dummy';

export const SearchInputStyled = styled(SearchInput)`
  max-width: 616px;
  margin: 48px 0;
`;

export const WrapperSection = styled.div`
  width: fit-content;
`;
