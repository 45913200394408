import {
  exportLexiconModalActions,
  importLexiconModalActions,
  lexiconPageHeaderActions,
  lexiconTableActions,
} from 'src/components-bl';

import { ILexiconReducerState } from 'src/app-state-types';
import { lexiconListActions } from 'src/components-bl/Lexicon/components/LexiconList/Actions/LexiconListActions';
import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { deepTagsProductContainerActions } from 'src/containers/DeepTagReports/components/DeepTagsProductContainer/Actions';
import { lexiconsContainerActions } from 'src/containers/Lexicon/components/LexiconsContainer/LexiconsContainer.actions';
import { deepTagReportsContainerSwitchActions } from 'src/containers/DeepTagReports/components/DeepTagReportsContainerSwitch/DeepTagReportsContainerSwitch.actions';
import { rootContainerActions } from 'src/root-container/Actions';
import { DomainEntityPath } from 'src/services';
import {
  editThematicTagContainerActions,
  exportLexiconContainerActions,
  importLexiconContainerActions,
} from 'src/containers';
import { useLexiconWSActions } from 'src/components-bl/Lexicon/hooks/useLexiconWS';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';
import { lexiconTablePageActions } from 'src/components-bl/Lexicon/components/LexiconsTablePage/LexiconTablePage.actions';
import { editLexiconRuleContainerActions } from 'src/containers/Lexicon/components/EditLexiconRuleContainer';
import { catalogExplorerContainerActions } from 'src/containers/CatalogManager/CatalogExplorerContainer';

const initialState: ILexiconReducerState = {
  items: undefined,
  metaData: undefined,
  showApplyAll: true,
  lexiconsMetadataList: undefined,
  allLexiconSupportedLanguages: undefined,
  currentThematicTag: undefined,
  currentLexiconRule: undefined,
  importExportFile: undefined,
  currentSelectedTagType: LexiconTagType.LexiconTags,
};

export const lexiconReducer = createReducer(initialState, builder => {
  builder

    .addCase(lexiconPageHeaderActions.publishLexicon.fulfilled, (state, action) => {
      return {
        ...state,
        metaData: action.payload,
      };
    })
    .addCase(lexiconPageHeaderActions.updateShowApplyAll, (state, action) => {
      return {
        ...state,
        showApplyAll: action.payload.shouldShow,
      };
    })
    .addCase(lexiconPageHeaderActions.resetLexiconMetadata, state => {
      return {
        ...state,
        metaData: initialState.metaData,
        showApplyAll: initialState.showApplyAll,
      };
    })
    .addCase(deepTagsProductContainerActions.getShopLexiconByLocale.fulfilled, (state, action) => {
      return {
        ...state,
        locale: action.meta.arg.locale,
        items: action.payload.data.tags,
        totalItems: action.payload.pagination.totalCount,
      };
    })
    .addCase(lexiconTableActions.getLexiconTags.fulfilled, (state, action) => {
      const isEmptyResult = !action.payload.data.tags.length;
      const isFromTheBeginning = (action.meta.arg.pagination?.skip ?? 0) === 0;
      const shouldOverride = isEmptyResult || isFromTheBeginning;

      const updatedItems = shouldOverride
        ? action.payload.data.tags
        : (state.items || [])?.concat(action.payload.data.tags);

      return {
        ...state,
        items: updatedItems,
        totalItems: action.payload.pagination.totalCount,
      };
    })
    .addCase(lexiconListActions.navigateTo, state => {
      return { ...initialState, allLexiconSupportedLanguages: state.allLexiconSupportedLanguages };
    })
    .addCase(rootContainerActions.initApp.fulfilled, (state, payload) => {
      const lexiconLanguages = payload.payload.languagesWithIcons.filter(language =>
        language.supportedDomains.includes(DomainEntityPath.Lexicon)
      );

      return { ...state, allLexiconSupportedLanguages: lexiconLanguages };
    })
    .addCase(editThematicTagContainerActions.getThematicTag.fulfilled, (state, action) => {
      return { ...state, currentThematicTag: action.payload };
    })
    .addCase(useLexiconWSActions.setImportExportProgress, (state, action) => {
      const importExportFile = state.importExportFile
        ? { ...state.importExportFile, progress: action.payload.progress }
        : undefined;

      return {
        ...state,
        importExportFile,
      };
    })
    .addCase(lexiconTableActions.reset, state => {
      return {
        ...state,
        items: initialState.items,
        totalItems: initialState.totalItems,
      };
    })
    .addCase(lexiconTablePageActions.setCurrentTagType, (state, action) => {
      return {
        ...state,
        currentSelectedTagType: action.payload.tagType,
      };
    })
    .addCase(editLexiconRuleContainerActions.getLexiconRule.fulfilled, (state, action) => {
      return {
        ...state,
        currentLexiconRule: action.payload,
      };
    })
    .addCase(editLexiconRuleContainerActions.resetLexiconRule, state => {
      return {
        ...state,
        currentLexiconRule: initialState.currentLexiconRule,
      };
    })
    .addMatcher(
      isAnyOf(
        exportLexiconModalActions.exportLexicon.pending,
        importLexiconModalActions.importLexicon.pending
      ),
      (state, action) => {
        return {
          ...state,
          importExportFile: {
            requestId: action.meta.arg.requestId,
            progress: 0,
          },
        };
      }
    )
    .addMatcher(
      isAnyOf(
        exportLexiconModalActions.exportLexicon.fulfilled,
        importLexiconModalActions.importLexicon.fulfilled
      ),
      (state, _) => {
        const importExportFile = state.importExportFile
          ? { ...state.importExportFile, progress: 100 }
          : undefined;

        return {
          ...state,
          importExportFile,
        };
      }
    )
    .addMatcher(
      isAnyOf(
        lexiconPageHeaderActions.getLexiconMetadata.fulfilled,
        useLexiconWSActions.getLexiconMetadata.fulfilled
      ),
      (state, action) => {
        return {
          ...state,
          metaData: action.payload,
        };
      }
    )
    .addMatcher(
      isAnyOf(
        exportLexiconModalActions.exportLexicon.rejected,
        exportLexiconContainerActions.navigateTo,
        importLexiconModalActions.importLexicon.rejected,
        importLexiconContainerActions.navigateTo
      ),
      state => {
        return {
          ...state,
          importExportFile: undefined,
        };
      }
    )

    .addMatcher(
      isAnyOf(
        editThematicTagContainerActions.getThematicTag.rejected,
        editThematicTagContainerActions.resetThematicTag
      ),
      state => {
        return { ...state, currentThematicTag: initialState.currentThematicTag };
      }
    )

    .addMatcher(
      isAnyOf(
        lexiconsContainerActions.getLexiconsMetadataList.fulfilled,
        deepTagReportsContainerSwitchActions.getLexiconsMetadataList.fulfilled,
        catalogExplorerContainerActions.getLexiconsMetadataList.fulfilled
      ),
      (state, action) => {
        return { ...state, lexiconsMetadataList: action.payload.lexiconMetadataList };
      }
    );
});
