import styled from '@emotion/styled';

export const BubbleContainerStyled = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const BubbleStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  text-indent: 8px;
  width: 100%;
  padding-right: 4px;
  background-color: white;
  border-top: 2px solid #0173ff;
  border-bottom: 2px solid #0173ff;
  border-left: 2px solid #0173ff;
  height: 32px;
  margin: 3px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Triangle1Styled = styled.div`
  position: absolute;
  top: -2px;
  right: -56px;
  height: 0;
  border-left: 28px solid white;
  border-right: 28px solid transparent;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  border-left-color: #0173ff;
  border-radius: 0px;
`;

export const Triangle2Styled = styled.div`
  position: absolute;
  width: 0px;
  height: 0;
  top: -1px;
  right: -52px;
  border-left: 27px solid white;
  border-right: 27px solid transparent;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  border-radius: 6px;
`;
