import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { useAppSelector, useIsFeaturePermitted, usePreviousState } from 'src/hooks';
import { catalogExplorerContainerActions } from './CatalogExplorerContainer.actions';
import { CatalogExplorerPage } from 'src/components-bl/CatalogManager/CatalogExplorer/CatalogExplorerPage';
import { FieldType, IShopCatalog, ShopDataField } from 'src/services';
import { useStoredCatalogDisplayPref } from './useStoredCatalogDisplayPref';
import { isAcceptedDataField } from 'src/components-bl/CatalogManager/CatalogExplorer/CatalogExplorer.helpers';
import { ShopFeature } from 'src/app-types/enums/shop-feature';

export const CatalogExplorerContainer = ({
  permittedRouteMap,
}: RoutedComponentProps): JSX.Element | null => {
  const { catalogName } = useParams<{ catalogName: string }>();

  const dispatch = useDispatch();
  const [aiTagsDataFieldsState, setAITagsDataFieldsState] = useState<ShopDataField[] | undefined>(
    []
  );
  const [isWaitingForDataFields, setIsWaitingForDataFields] = useState<boolean>(true);

  const {
    catalogs,
    catalogProducts,
    totalCatalogProducts,
    totalFilteredProducts,
    shopId,
    shopCatalogDataFields,
    languages,
    displayPrefStoreState,
    lexiconsMetadataList,
    allLexiconSupportedLanguages,
  } =
    useAppSelector(state => {
      const currentShopId = state.shop.current.shopId;

      return {
        catalogs: state.catalogManager.catalogs,
        catalogProducts: state.catalogManager.catalogExplorer.products,
        totalCatalogProducts: state.catalogManager.catalogExplorer.totalCatalogProducts,
        totalFilteredProducts: state.catalogManager.catalogExplorer.totalFilteredProducts,
        shopId: currentShopId,
        shopCatalogDataFields: state.dataFields.dataFields,
        languages: state.meta.languages,
        displayPrefStoreState:
          state.catalogManager.catalogExplorer.displayPreferences?.[currentShopId]?.[catalogName],
        lexiconsMetadataList: state.lexicon.lexiconsMetadataList,
        allLexiconSupportedLanguages: state.lexicon.allLexiconSupportedLanguages,
      };
    }, shallowEqual) || {};

  const isMerchRulesAITagsEnabled = useIsFeaturePermitted(ShopFeature.MerchandisingRulesAiTags);

  const { displayFieldsByType } = useStoredCatalogDisplayPref({
    shopId,
    catalogName,
    displayPrefStoreState,
    catalogTypeDataFields: shopCatalogDataFields,
    aiTagsTypeDataFields: aiTagsDataFieldsState,
    isMerchRulesAITagsEnabled,
    isWaitingForDataFields,
  });

  const previousShopId = usePreviousState(shopId);

  const navigateToCatalogsManagerList = useCallback(() => {
    if (permittedRouteMap.catalogManager?.path && shopId) {
      const path = generatePath(permittedRouteMap.catalogManager?.path, { shopId });

      dispatch(
        catalogExplorerContainerActions.navigateTo({
          navigateTo: path,
        })
      );
    }
  }, [permittedRouteMap.catalogManager?.path, shopId]);

  useEffect(() => {
    if (previousShopId && shopId && shopId !== previousShopId) {
      navigateToCatalogsManagerList();
    }
  }, [navigateToCatalogsManagerList, previousShopId, shopId]);

  const fetchDataFields = async () => {
    setIsWaitingForDataFields(true);

    try {
      // 1. Fetching "ai_tags"
      if (isMerchRulesAITagsEnabled) {
        const aiTagsDataFields = (await dispatch(
          catalogExplorerContainerActions.getAITagsDataFields({ shopId })
        )) as unknown as { error?: any; payload?: { dataFields: ShopDataField[] } };

        setAITagsDataFieldsState(aiTagsDataFields?.payload?.dataFields || []);
      }

      // 2. Fetching "catalog_field"
      await dispatch(catalogExplorerContainerActions.getCatalogDataFields({ shopId }));
    } catch (error) {
      console.error(error);
    } finally {
      setIsWaitingForDataFields(false);
    }
  };

  useEffect(() => {
    fetchDataFields();
  }, [shopId]);

  useEffect(() => {
    if (!shopId || !allLexiconSupportedLanguages) {
      return;
    }

    dispatch(
      catalogExplorerContainerActions.getLexiconsMetadataList({
        shopId,
        lexiconLanguages: allLexiconSupportedLanguages,
      })
    );
  }, [shopId, allLexiconSupportedLanguages]);

  useEffect(() => {
    if (previousShopId && previousShopId !== shopId) {
      fetchDataFields();
    }
  }, [shopId, previousShopId]);

  useEffect(() => {
    const catalogExistsForShop = catalogs?.some(catalog => catalog.name == catalogName);

    if (catalogs && !catalogExistsForShop) {
      console.warn(`Catalog - ${catalogName} doesn't exist in shop`);
      navigateToCatalogsManagerList();
    }
  }, [catalogs, catalogName]);

  if (!shopId) {
    return null;
  }

  const selectedCatalog: IShopCatalog | undefined = useMemo(() => {
    return catalogs?.find(catalog => catalog.name === catalogName);
  }, [catalogs]);

  const languageMetadata = useMemo(
    () => languages.find(language => language.locale === selectedCatalog?.defaultLocale),
    [selectedCatalog, languages]
  );

  /**
   * All supported data fields by type
   */
  const dataFieldsByType = useMemo(() => {
    if (isWaitingForDataFields) return undefined;

    const catalogDataFields = (shopCatalogDataFields || []).filter(dataField => {
      return isAcceptedDataField({
        dataField,
        catalogName,
        hasAITagsPemission: isMerchRulesAITagsEnabled,
      });
    });

    const aiTagsDataFields = (aiTagsDataFieldsState || []).filter(dataField => {
      return isAcceptedDataField({
        dataField,
        catalogName,
        hasAITagsPemission: isMerchRulesAITagsEnabled,
      });
    });

    return {
      [FieldType.CatalogField]: catalogDataFields,
      [FieldType.AiTag]: aiTagsDataFields,
    };
  }, [
    isWaitingForDataFields,
    aiTagsDataFieldsState,
    shopCatalogDataFields,
    catalogName,
    isMerchRulesAITagsEnabled,
  ]);

  return (
    <CatalogExplorerPage
      catalogProducts={catalogProducts}
      totalCatalogProducts={totalCatalogProducts}
      totalFilteredProducts={totalFilteredProducts}
      catalog={selectedCatalog}
      dataFieldsByType={dataFieldsByType}
      isMerchRulesAITagsEnabled={isMerchRulesAITagsEnabled}
      navigateToCatalogsList={navigateToCatalogsManagerList}
      displayFieldsByType={displayFieldsByType}
      catalogName={catalogName}
      shopId={shopId}
      dispatch={dispatch}
      languageMetadata={languageMetadata}
      isWaitingForDataFields={isWaitingForDataFields}
      hasCustomLexiconConfig={!!lexiconsMetadataList?.length}
    />
  );
};
