import React from 'react';
import { TypographyType, TypographyVariant } from '../../../components-dummy';
import {
  ContentBlockStyled,
  ContentBlockSubtitleStyled,
  ContentBlockTextStyled,
  ContentBlockTitleStyled,
} from './CollectionsTranslationFileUploadModal.styles';

export const ContentBlock = () => {
  return (
    <ContentBlockStyled>
      <ContentBlockTitleStyled type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
        How to use?
      </ContentBlockTitleStyled>
      <ContentBlockSubtitleStyled
        type={TypographyType.Paragraph}
        variant={TypographyVariant.SmallMedium}
      >
        1. Download the template
      </ContentBlockSubtitleStyled>
      <ContentBlockTextStyled
        type={TypographyType.Paragraph}
        variant={TypographyVariant.SmallRegular}
      >
        Download the translations file with collection IDs and translations for each locale (e.g.,
        en_GB, fr_FR).
      </ContentBlockTextStyled>
      <ContentBlockSubtitleStyled
        type={TypographyType.Paragraph}
        variant={TypographyVariant.SmallMedium}
      >
        2. Edit your translations
      </ContentBlockSubtitleStyled>
      <ContentBlockTextStyled
        type={TypographyType.Paragraph}
        variant={TypographyVariant.SmallRegular}
      >
        Edit the template to include your translations per collection ID. Ensure all necessary
        locales are covered.
      </ContentBlockTextStyled>
      <ContentBlockSubtitleStyled
        type={TypographyType.Paragraph}
        variant={TypographyVariant.SmallMedium}
      >
        3. Upload the file
      </ContentBlockSubtitleStyled>
      <ContentBlockTextStyled
        type={TypographyType.Paragraph}
        variant={TypographyVariant.SmallRegular}
      >
        Drag & drop or browse to upload the updated file (max 100MB).
      </ContentBlockTextStyled>
      <ContentBlockSubtitleStyled
        type={TypographyType.Paragraph}
        variant={TypographyVariant.SmallMedium}
      >
        Note
      </ContentBlockSubtitleStyled>
      <ContentBlockTextStyled
        type={TypographyType.Paragraph}
        variant={TypographyVariant.SmallRegular}
      >
        Uploading a file will <b>overwrite existing</b> translations for the specified collections.
        Missing translations will be removed.
      </ContentBlockTextStyled>
    </ContentBlockStyled>
  );
};
