import React from 'react';
import { DataFieldsSelectHeader } from '../../DataFieldsSelectHeader';
import { DisplayedFieldsItems } from '../../DisplayedFieldsItems/DisplayedFieldsItems';
import {
  Typography,
  TypographyType,
  TypographyVariant,
  AvailableIcons,
  Icon,
} from 'src/components-dummy';
import {
  AITagsTryItNowPromo,
  SectionContentStyled,
  SectionContainerStyled,
  SectionHeaderStyled,
} from '../DataFieldsByTypesSections.styles';
import { DataFieldsSectionProps } from '../DataFieldsByTypeSections.types';
import { NoAITagsLearnMoreInfo } from './NoAITagsLearnMoreInfo';

interface AITagsFieldsSectionProps extends DataFieldsSectionProps {
  isMerchRulesAITagsEnabled: boolean;
  hasAIDataFields: boolean;
}

export const AITagsFieldsSection = React.memo(
  ({
    displayedFormList: dataFieldsFormList,
    unusedDataFieldsOptions,
    locale,
    isMerchRulesAITagsEnabled,
    hasAIDataFields,
    onItemValueChange,
    onItemRemove,
    onListOrderChange,
    onAddNewItems,
  }: AITagsFieldsSectionProps) => {
    if (!isMerchRulesAITagsEnabled || !hasAIDataFields) {
      console.info(
        `${isMerchRulesAITagsEnabled ? 'AI tags merch rules not enabled' : 'Empty AI data fields list'}`
      );

      return <NoAITagsLearnMoreInfo />;
    }

    return (
      <SectionContainerStyled>
        <SectionHeaderStyled>
          <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
            AI Tags
          </Typography>
          <AITagsTryItNowPromo>
            <Icon name={AvailableIcons.AITagV2} />{' '}
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
              NEW! &nbsp;
            </Typography>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              Try it now!
            </Typography>
          </AITagsTryItNowPromo>
        </SectionHeaderStyled>
        <SectionContentStyled>
          <DataFieldsSelectHeader
            unusedDataFieldsOptions={unusedDataFieldsOptions}
            locale={locale}
            onChange={onAddNewItems}
          />
          <DisplayedFieldsItems
            dataFieldsFormList={dataFieldsFormList}
            unusedDataFieldsOptions={unusedDataFieldsOptions}
            onItemRemove={onItemRemove}
            onItemValueChange={onItemValueChange}
            onListOrderChange={onListOrderChange}
            onAddNewItems={onAddNewItems}
          ></DisplayedFieldsItems>
        </SectionContentStyled>
      </SectionContainerStyled>
    );
  }
);
