import React from 'react';
import ListMui, { ListProps as ListMuiProps } from '@mui/material/List';

type ListV2Props = ListMuiProps;

export const ListV2 = React.forwardRef<HTMLUListElement, ListV2Props>(
  (props: ListMuiProps, ref): JSX.Element => {
    return <ListMui {...props} ref={ref} />;
  }
);
