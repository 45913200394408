import React from 'react';
import { generatePath } from 'react-router';
import { useAppSelector } from '../../hooks';
import { Page } from '../../components-dummy';
import { collectionNavigationActionMethods, CollectionsHeader } from '../../components-bl';
import { CollectionsMain } from './components';
import { useCreateDefaultCollection } from '../CreateCollection/useCreateDefaultCollection';
import { useEditCollection } from '../EditCollection/useEditCollection';
import { useGetCollectionsList } from '../../components-bl/Collections/CollectionsList/useGetCollectionsList';
import type { CollectionsContainerProps } from './types';
import './Collections.scss';

export const Collections = ({ permittedRouteMap, shopId, dispatch }: CollectionsContainerProps) => {
  const { collections } = useAppSelector(state => state.collections);
  const isListEmpty = !collections.length;

  useGetCollectionsList({ shopId, dispatch });

  const { onCreateDefaultCollection } = useCreateDefaultCollection({
    shopId,
    dispatch,
  });

  const { onEditCollection } = useEditCollection({
    shopId,
    dispatch,
  });

  const onManageTranslations = () => {
    if (permittedRouteMap.collectionsTranslationFileUpload) {
      dispatch(
        collectionNavigationActionMethods.navigateTo({
          navigateTo: generatePath(permittedRouteMap.collectionsTranslationFileUpload.path, {
            shopId,
          }),
        })
      );
    }
  };

  return shopId ? (
    <Page className='collections'>
      <Page.Header>
        <CollectionsHeader
          onCreateDefaultCollection={onCreateDefaultCollection}
          onManageTranslations={onManageTranslations}
          isListEmpty={isListEmpty}
        />
      </Page.Header>
      <Page.Content className='collections'>
        <CollectionsMain
          shopId={shopId}
          dispatch={dispatch}
          collections={collections}
          isListEmpty={isListEmpty}
          onCreateDefaultCollection={onCreateDefaultCollection}
          onEditCollection={onEditCollection}
        />
      </Page.Content>
    </Page>
  ) : null;
};
