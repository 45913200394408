import { DomainGroup } from 'src/components-bl';
import {
  DomainNodeBase,
  DomainNode,
  DomainGroupMap,
  SubDomainGroup,
  ParentDomain,
  ParentDomainBase,
  SubDomainGroupBase,
  DiffSchemaField,
} from '../types';
import { augmentedSearchDomainGroup } from './augmented-search';
import { discoveryButtonDomainGroup } from './discovery-button';
import { merchandisingRulesDomainGroup } from './merchandising-rules';
import { personalizationDomainGroup } from './personalization';
import { shopTheLookDomainGroup } from './shop-the-look';
import { similarItemsDomainGroup } from './similar-items';
import { visualSearchDomainGroup } from './visual-search';
import { recentlyViewedDomainGroup } from './recently-viewed';
import { recEnginesGeneralSettingsDomainGroup } from './rec-engines-general-settings';
import { storiesDomainGroup } from './stories';
import { boughtTogetherDomainGroup } from './bought-together';
import { discoveryBannerDomainGroup } from './discovery-banner';
import { enableUiTestDomainGroup } from './enable-ui-test';
import { collectionsDomainGroup, collectionRulesDomainGroup } from './collections';
import { uiTemplatesDomainGroup } from './ui-templates';
import { collectionSettingsDomainGroup } from './collection-settings';
import { filtersSetsDomainGroupSchema } from './filters';
import { customInspirationsGalleryDomainGroup } from './custom-inspirations-galleries';
import { useKnnDomainGroup } from './use-knn';
import { collectionsTranslationsDomainGroup } from './collections/collections-translations-domain-group';

export const domainGroupsSettings = [
  augmentedSearchDomainGroup,
  boughtTogetherDomainGroup,
  collectionRulesDomainGroup,
  collectionsDomainGroup,
  collectionSettingsDomainGroup,
  discoveryBannerDomainGroup,
  discoveryButtonDomainGroup,
  enableUiTestDomainGroup,
  filtersSetsDomainGroupSchema,
  merchandisingRulesDomainGroup,
  personalizationDomainGroup,
  collectionsTranslationsDomainGroup,
  recEnginesGeneralSettingsDomainGroup,
  recentlyViewedDomainGroup,
  shopTheLookDomainGroup,
  similarItemsDomainGroup,
  storiesDomainGroup,
  uiTemplatesDomainGroup,
  visualSearchDomainGroup,
  customInspirationsGalleryDomainGroup,
  useKnnDomainGroup,
] as DomainNodeBase[];

const buildPathToFieldSchemaMap = (
  domainParentSettings: SubDomainGroupBase
): Record<string, DiffSchemaField<any>> => {
  const map = Object.entries(domainParentSettings.fieldsSchema).reduce(
    (accumulator, schemaFieldEntry) => {
      const [fieldKey, fieldSchema] = schemaFieldEntry as [string, DiffSchemaField<any>];

      const paths = fieldSchema?.relevantFieldPaths;
      if (paths?.length) {
        paths.forEach(path => {
          accumulator[path as string] = fieldSchema;
        });
      } else {
        accumulator[fieldKey] = fieldSchema;
      }

      return accumulator;
    },
    {} as Record<string, DiffSchemaField<any>>
  );

  return map;
};

const prepareSubDomain = (domainParentSettings: SubDomainGroupBase): SubDomainGroup<any> => {
  const pathToFieldSchemaMap = buildPathToFieldSchemaMap(domainParentSettings);
  const domainGroup: SubDomainGroup = {
    ...domainParentSettings,
    changedEntities: [],
    pathToFieldSchemaMap,
  };

  return domainGroup;
};

const prepareDomainParent = (domainParentSettings: ParentDomainBase): DomainGroup => {
  const convertedSubDomains = domainParentSettings.subDomains.map(subDomain => {
    const preparedSubDomain = prepareSubDomain(subDomain);
    return preparedSubDomain;
  });

  const domainGroup: ParentDomain = {
    ...domainParentSettings,
    subDomains: convertedSubDomains,
  } as ParentDomain;

  return domainGroup;
};

const prepareDomainGroupsFromSettings = (): DomainNode<any>[] => {
  const domainGroups = domainGroupsSettings.map(domainSettings => {
    let domainGroup: DomainNode;
    if ('subDomains' in domainSettings) {
      domainGroup = prepareDomainParent(domainSettings) as DomainNode<any>;
    } else {
      domainGroup = prepareSubDomain(domainSettings);
    }
    return domainGroup;
  });

  return domainGroups;
};

export const getDomainGroupMap = (): DomainGroupMap => {
  const domainGroupsFromSettings = prepareDomainGroupsFromSettings();
  const domainGroupMap = domainGroupsFromSettings.reduce((map, domainGroup) => {
    map.set(domainGroup.domainKey, domainGroup);
    return map;
  }, new Map() as DomainGroupMap);
  return domainGroupMap;
};
