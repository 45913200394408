import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from 'src/services';

const authService = new Auth();

export const lastUserLocationActions = {
  navigateTo: createAction<{ navigateTo: string }>('LastUserLocation/NavigateTo'),

  getCurrentUser: createAsyncThunk(
    'LastUserLocation/GetCurrentUser',
    async (_, { rejectWithValue }) => {
      try {
        const { user } = await authService.me();
        return { user };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
