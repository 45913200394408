import React from 'react';
import {
  ParagraphStyled,
  ProductTileStyled,
  LinkButtonStyled,
  IconStyled,
  HeaderStyled,
} from './ProductTile.styles';
import {
  AvailableIcons,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';

export interface ProductTileProps {
  title: string;
  onClick: () => void;
  iconName: AvailableIcons;
  paragraphText: string;
  cta: string;
}

export const ProductTile = ({
  title,
  onClick,
  iconName,
  paragraphText,
  cta,
}: ProductTileProps): JSX.Element => {
  return (
    <ProductTileStyled>
      <HeaderStyled>
        <IconStyled name={iconName} />
        <Typography variant={TypographyVariant.LargeBold} type={TypographyType.Body}>
          {title}
        </Typography>
      </HeaderStyled>
      <ParagraphStyled>{paragraphText}</ParagraphStyled>
      <LinkButtonStyled size='small' variant='outlined' onClick={onClick}>
        {cta}
      </LinkButtonStyled>
    </ProductTileStyled>
  );
};
