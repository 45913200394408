import React, { useMemo } from 'react';
import { MenuItem, Select, SelectType } from '../../../components-dummy';

interface Props {
  onManageTranslations: VoidFunction;
}

export const CollectionsSettings = ({ onManageTranslations }: Props) => {
  const settingsOptions = useMemo(
    () => [
      {
        text: 'Manage Translations',
        value: 'manage_translations',
        onClick: onManageTranslations,
      },
    ],
    [onManageTranslations]
  );

  return (
    <Select type={SelectType.Menu} value='Settings'>
      {settingsOptions.map(({ text, value, onClick }) => (
        <MenuItem key={value} value={value} onClick={onClick}>
          {text}
        </MenuItem>
      ))}
    </Select>
  );
};
