import React from 'react';
import { CreateCollectionButton } from '../CreateCollectionButton';
import { CollectionsHeaderWrapper, CollectionTitle } from './CollectionsHeader.styles';
import { CollectionsSettings } from './CollectionsSettings';

interface CollectionsHeaderProps {
  onCreateDefaultCollection: VoidFunction;
  onManageTranslations: VoidFunction;
  isListEmpty: boolean;
}

export const CollectionsHeader = ({
  onCreateDefaultCollection,
  onManageTranslations,
  isListEmpty,
}: CollectionsHeaderProps) => {
  return (
    <CollectionsHeaderWrapper>
      <CollectionTitle>Collections</CollectionTitle>
      {!isListEmpty && <CollectionsSettings onManageTranslations={onManageTranslations} />}
      <CreateCollectionButton
        onCreateDefaultCollection={onCreateDefaultCollection}
        buttonText='Create collection'
      />
    </CollectionsHeaderWrapper>
  );
};
