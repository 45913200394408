import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AvailableIcons,
  Button,
  Icon,
  MultiSelectMenuWithSearch,
  MultiSelectOption,
  PopUp,
} from 'src/components-dummy';
import {
  TagMultiSelectContentStyled,
  MultiSelectWrapperStyled,
  MultiSelectFooterStyled,
} from '../CatalogExplorerFilters/CatalogExplorerFilters.styles';
import { isEqual } from 'lodash';
import { getLanguageCodeByLocale, sortArrayByLocale } from 'src/utils';
import {
  AddDataFieldsContentStyled,
  AddTagButtonWrapperStyled,
} from './CatalogExplorerDisplayPreferences.styles';

export type FilterOption = { key: string; displayName: string };

export interface AddDataFieldsProps {
  filterOptions: MultiSelectOption[];
  selectedFilterKeys?: string[];
  locale: string;
  isDisabled: boolean;
  onChange: (selectedItems: string[]) => void;
}

export const AddDataFields = ({
  filterOptions,
  selectedFilterKeys,
  locale,
  isDisabled,
  onChange,
}: AddDataFieldsProps): JSX.Element => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [formSelectedOptions, setFormSelectedOptions] = useState([]);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const canApplySelection = useMemo(() => {
    if (!formSelectedOptions.length) return false;

    return !isEqual(filterOptions, formSelectedOptions) && !!formSelectedOptions.length;
  }, [formSelectedOptions, filterOptions]);

  const sortedFilterOptions = useMemo(() => {
    return Object.values(filterOptions || []).sort((a, b) => {
      return sortArrayByLocale({
        a: a.text,
        b: b.text,
        languageCode: getLanguageCodeByLocale(locale || 'en_US'),
        isAscending: true,
      });
    });
  }, [locale, filterOptions]);

  const handleOptionsChange = (selectedOptions: string[]) => {
    setFormSelectedOptions(selectedOptions);
  };

  useEffect(() => {
    setFormSelectedOptions(selectedFilterKeys || []);
  }, [selectedFilterKeys]);

  const toggleShowPopUp = useCallback(
    (shouldShowPopUp: boolean) => {
      setShowPopUp(shouldShowPopUp);
    },
    [setShowPopUp]
  );

  const closePopUp = useCallback(() => {
    toggleShowPopUp(false);
  }, [toggleShowPopUp]);

  const onConfirmSelection = useCallback(() => {
    if (canApplySelection) {
      onChange(formSelectedOptions);
      setFormSelectedOptions([]);
      closePopUp();
    }
  }, [onChange, formSelectedOptions, canApplySelection, closePopUp]);

  useEffect(() => {
    if (showPopUp) {
      searchInputRef.current?.focus();
    }
  }, [showPopUp]);

  return (
    <PopUp
      show={showPopUp}
      onShow={toggleShowPopUp}
      closeOnTriggerClick={false}
      hideOnScroll={false}
      disabled={isDisabled}
      position='bottom right'
    >
      <PopUp.Trigger>
        <AddTagButtonWrapperStyled
          isClickable={!isDisabled}
          clicked={showPopUp}
          isDisabled={isDisabled}
        >
          <Button variant='tertiary' size='small' disabled={isDisabled}>
            <Icon name={AvailableIcons.Plus} />
            Add
          </Button>
        </AddTagButtonWrapperStyled>
      </PopUp.Trigger>
      <PopUp.Content>
        <AddDataFieldsContentStyled>
          <MultiSelectWrapperStyled>
            <TagMultiSelectContentStyled>
              <MultiSelectMenuWithSearch
                searchPlaceholder='Search...'
                options={sortedFilterOptions}
                selectedOptions={selectedFilterKeys}
                onChange={handleOptionsChange}
                enableSelectAll={true}
                searchInputRef={searchInputRef}
              />
            </TagMultiSelectContentStyled>
            <MultiSelectFooterStyled>
              <Button variant='tertiary' onClick={closePopUp}>
                Cancel
              </Button>
              <Button variant='primary' onClick={onConfirmSelection} disabled={!canApplySelection}>
                Add
              </Button>
            </MultiSelectFooterStyled>
          </MultiSelectWrapperStyled>
        </AddDataFieldsContentStyled>
      </PopUp.Content>
    </PopUp>
  );
};
