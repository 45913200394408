import { AccountBase } from '../accounts/account-base';
import { UserRoles } from './user-roles';

export interface LastVisitedObject {
  account: number;
  shop?: number;
  uri?: string;
}

export interface UserBackEnd {
  accessLevel: UserRoles;
  accounts: number[];
  email: string;
  lastVisited: LastVisitedObject;
  online: boolean;
  multipleAccess: boolean;
  permissions: UserPermissions;
  userId: number;
  firstName: string;
  lastName: string;
  visitedLately: number[] | AccountBase[];
  lastLoginDate?: string | undefined;
  logoColor: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface User {
  userId: number;
  email: string;
  online: boolean;
  firstName: string;
  lastName: string;
  role: UserRoles;
  accountId: number;
  lastLoginDate?: Date | undefined;
  lastVisited: LastVisitedObject;
}

export interface UserPermissions {
  account: EntityPermissions;
  shop: EntityPermissions;
  merchandisingRules: EntityPermissions;
  shopSettings: EntityPermissions;
  ranking: EntityPermissions;
  user: EntityPermissions;
}

export interface EntityPermissions {
  view?: UserRoles;
  manage?: UserRoles;
  create?: UserRoles;
  edit?: UserRoles;
  delete?: UserRoles;
}

export enum PermissionAction {
  Read = 1,
  Write,
  Delete,
  Execute,
}
