import React, { useMemo } from 'react';
import { TableHeadRow } from './CatalogExplorerTable.types';
import { MANDATORY_COLUMNS } from './CatalogExplorerTable.config';
import { DisplayedFieldItem } from '../CatalogExplorerDisplayPreferences/types';
import { DisplayFieldsByType } from '../types';
import { getItemDisplayText } from '../CatalogExplorer.helpers';

interface UseDynamicTableColumnsProps {
  displayFieldsPreferencesByType: DisplayFieldsByType;
  displayFieldItemByName: Record<string, DisplayedFieldItem>;
  locale?: string;
}

type UseDynamicTableColumnsResult = {
  tableColumnsObject: Record<string, TableHeadRow>;
  tableColumnsOrderedList: TableHeadRow[];
};

export const useDynamicTableColumns = ({
  displayFieldsPreferencesByType,
  displayFieldItemByName,
}: UseDynamicTableColumnsProps): UseDynamicTableColumnsResult => {
  const allDisplayFieldItems = useMemo(
    () =>
      [
        ...displayFieldsPreferencesByType.catalog_field,
        ...displayFieldsPreferencesByType.ai_tag,
      ] as DisplayedFieldItem[],
    [displayFieldsPreferencesByType]
  );

  const dynamicColumnsObject = React.useMemo(() => {
    const initial = {
      [MANDATORY_COLUMNS.index.accessor]: MANDATORY_COLUMNS.index,
    };

    const fieldsColumns = Object.values(displayFieldItemByName).reduce(
      (prev, field) => {
        if (MANDATORY_COLUMNS[field.value]) {
          const column = {
            ...MANDATORY_COLUMNS[field.value],
            header: getItemDisplayText({
              fieldType: field.fieldType,
              value: field.value,
              text: field.text,
            }),
          };

          return {
            ...prev,
            [field.value]: column,
          };
        }

        return {
          ...prev,
          [field.value]: {
            header: getItemDisplayText({
              fieldType: field.fieldType,
              value: field.value,
              text: field.text,
              shouldAddAITagsText: true,
            }),
            accessor: field.value,
            minWidth: 64,
          },
        };
      },
      initial as Record<string, TableHeadRow>
    );

    return { fieldsColumns };
  }, [displayFieldItemByName]);

  const tableColumnsObject = dynamicColumnsObject.fieldsColumns;

  const tableColumnsOrderedList = React.useMemo(() => {
    const allOrderedColumnsList: TableHeadRow[] = allDisplayFieldItems.map(
      displayFieldItem => tableColumnsObject[displayFieldItem.value]
    );

    return [tableColumnsObject.index, ...allOrderedColumnsList];
  }, [tableColumnsObject]);

  return {
    tableColumnsObject,
    tableColumnsOrderedList,
  };
};
