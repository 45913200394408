import { FeedDataFieldType, FieldType, ShopDataField } from 'src/services/src/service/types';
import { MANDATORY_DATA_FIELDS_MAP } from './categoryExplorer.config';

export function addSeparatorsToNumber(number) {
  return new Intl.NumberFormat('en-US').format(number);
}

/**
 * Currently we're supporting "catalog_field" data fields (without "tagging" / "tags").
 */
export const isAcceptedDataField = ({
  dataField,
  catalogName,
  hasAITagsPemission,
}: {
  dataField: ShopDataField;
  catalogName: string;
  hasAITagsPemission: boolean;
}) => {
  const supportsCatalogName = dataField.catalogNames.includes(catalogName);
  const isTaggingField = dataField.name === 'tagging';
  const isTagsField = dataField.name === 'tags';

  const isCatalogField = dataField.fieldType === FieldType.CatalogField;
  const isAITagAndPermitted = dataField.fieldType === FieldType.AiTag && hasAITagsPemission;

  const isPermittedFieldType = isCatalogField || isAITagAndPermitted;

  return supportsCatalogName && isPermittedFieldType && !isTaggingField && !isTagsField;
};

export const isAcceptedDataFieldForFilters = ({
  dataFieldTypes,
}: {
  dataFieldTypes: ShopDataField['types'];
}) => {
  const supportsTextFiltering = dataFieldTypes.includes(FeedDataFieldType.Text);
  const supportsKeywordFiltering = dataFieldTypes.includes(FeedDataFieldType.Keyword);

  // Ignore fields that only support text filtering and has no "keyword" filtering
  return !(supportsTextFiltering && !supportsKeywordFiltering);
};

export const getItemDisplayText = ({
  value,
  text,
  fieldType,
  shouldAddAITagsText = false,
}: {
  value: string;
  text: string;
  fieldType: FieldType;
  shouldAddAITagsText?: boolean;
}) => {
  const customText =
    fieldType === FieldType.AiTag && shouldAddAITagsText ? `${text} (AI Tags)` : text;

  if (!MANDATORY_DATA_FIELDS_MAP[value]) {
    return customText;
  }

  // Display name was not set in shop config (default - same as value)
  const shouldUseMandatoryStaticName = customText === value;

  return shouldUseMandatoryStaticName ? MANDATORY_DATA_FIELDS_MAP[value].text : customText;
};
