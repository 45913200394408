import styled from '@emotion/styled';
import { Icon } from 'src/components-dummy';
import { css } from '@emotion/react';

/**
 * List
 */
export const DisplayedFieldsItemsStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DraggableListWrapperStyled = styled.div`
  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }
`;

/**
 * Item.
 */
export const DisplayedItemStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DisplayedItemRowStyled = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.3;
    `}

  .data-field-item-select {
    width: 100%;
  }

  .dropdown-with-search > div:first-of-type {
    max-width: 334px;
    width: 334px;
  }
`;

export const IconWrapperStyled = styled.div<{
  isPointerCursor?: boolean;
  isDisable?: boolean;
  isHidden?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;

  ${({ isPointerCursor }) =>
    isPointerCursor &&
    css`
      cursor: pointer;
    `}

  ${({ isHidden }) =>
    isHidden &&
    css`
      visibility: hidden;
    `}

  & > svg path {
    ${({ isDisable }) =>
      isDisable &&
      css`
        fill: #c5c5c5;
      `}
  }
`;

export const DragIconStyled = styled(Icon)``;
export const CloseIconStyled = styled(Icon)`
  margin: 4px;
  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-10']};
  }
`;
