import styled from '@emotion/styled';
import { ConfirmationDialog, Typography } from '../../../components-dummy';

export const UploadConfirmationDialogStyled = styled(ConfirmationDialog)`
  width: 600px;
  color: #1e1e1e;
`;

export const HeaderStyled = styled(ConfirmationDialog.Header)`
  border-bottom: 1px solid rgb(235, 235, 235);
`;

export const ContentBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  margin: 16px 0;
  background-color: #e5f1ff;
  border-radius: 8px;
`;

export const ContentBlockTitleStyled = styled(Typography)`
  margin-bottom: 8px;
`;

export const ContentBlockSubtitleStyled = styled(Typography)`
  color: #6e6e71;
`;

export const ContentBlockTextStyled = styled(Typography)`
  margin-left: 16px;
`;

export const FooterStyled = styled(ConfirmationDialog.Footer)`
  gap: 4px;
`;

export const UploadProgressStyled = styled(Typography)`
  display: flex;
  align-items: center;
  flex: 1;
`;
