import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { AxiosProgressEvent } from 'axios';
import { Dispatch } from '../../../components-dummy/types';
import {
  Button,
  ConfirmationDialog,
  TypographyType,
  TypographyVariant,
} from '../../../components-dummy';
import { ContentBlock } from './ContentBlock';
import { FileUploader } from '../../../components-dummy/FileUploader';
import { collectionNavigationActionMethods } from '../actions';
import { collectionsTranslationsActions } from './CollectionsTranslationsActions';
import {
  FooterStyled,
  HeaderStyled,
  UploadConfirmationDialogStyled,
  UploadProgressStyled,
} from './CollectionsTranslationFileUploadModal.styles';

interface Props {
  shopId: number;
  dispatch: Dispatch;
  onCollectionsTranslationsModalClose: VoidFunction;
  variantId?: string;
}

export const CollectionsTranslationFileUploadModal = ({
  shopId,
  dispatch,
  onCollectionsTranslationsModalClose: onModalClose,
  variantId,
}: Props): JSX.Element | null => {
  const [translationFiles, setTranslationFiles] = useState<File[] | undefined>(undefined);

  const [isDirty, setIsDirty] = useState(false);

  const uploadCancellationRef = useRef<AbortController | null>(null);

  const [uploadProgress, setUploadProgress] = useState<number | undefined>(undefined);

  const isInProgress = uploadProgress !== undefined;

  const updateIsDirty = useCallback(
    (newIsDirty: boolean) => {
      setIsDirty(newIsDirty);
      dispatch(collectionNavigationActionMethods.notifyIsDirty({ isDirty: newIsDirty }));
    },
    [dispatch, setIsDirty]
  );

  const onTranslationChange = (newTranslationFiles: File[] | undefined) => {
    setTranslationFiles(newTranslationFiles);
    updateIsDirty(true);
  };

  const onDownloadTranslations = () => {
    dispatch(collectionsTranslationsActions.export({ shopId, variantId }));
  };

  const cancelUploadProgress = () => {
    if (!uploadCancellationRef.current) {
      return;
    }

    try {
      uploadCancellationRef.current.abort();
    } catch (error) {
      console.error(error);
    } finally {
      uploadCancellationRef.current = null;
    }
  };

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    if (
      !progressEvent.upload ||
      progressEvent.progress === undefined ||
      !uploadCancellationRef.current ||
      uploadCancellationRef.current.signal.aborted
    ) {
      return;
    }

    const PERCENT_MULTIPLIER = 100;

    const progressRatio = Math.ceil(progressEvent.progress * PERCENT_MULTIPLIER);

    setUploadProgress(progressRatio);
  };

  const onApply = useCallback(async () => {
    setUploadProgress(0);

    cancelUploadProgress();

    uploadCancellationRef.current = new AbortController();

    await dispatch(
      collectionsTranslationsActions.import({
        file: translationFiles?.[0],
        shopId,
        onUploadProgress,
        cancellationSignal: uploadCancellationRef.current.signal,
        variantId,
      })
    );

    updateIsDirty(false);
    onModalClose();
  }, [shopId, translationFiles, updateIsDirty]);

  useEffect(() => {
    dispatch(collectionNavigationActionMethods.notifyIsDirty({ isDirty }));
  }, [isDirty]);

  useEffect(() => {
    return () => {
      cancelUploadProgress();
    };
  }, []);

  return (
    <UploadConfirmationDialogStyled onCancel={onModalClose}>
      <HeaderStyled>
        <ConfirmationDialog.Title>Manage Translations</ConfirmationDialog.Title>
      </HeaderStyled>
      <ConfirmationDialog.Content>
        <ConfirmationDialog.ContentMainText>
          You can update translations for your collection carousels in bulk.
        </ConfirmationDialog.ContentMainText>
        <ContentBlock />
        <FileUploader.CSV
          selectedFiles={translationFiles}
          onChange={files => onTranslationChange(files)}
          disabled={isInProgress}
          title='Upload file'
          downloadTitle='Download Updated Translations File'
          onExampleClick={onDownloadTranslations}
        />
      </ConfirmationDialog.Content>
      <FooterStyled>
        <Button variant='primary' onClick={onApply} disabled={!isDirty} loading={isInProgress}>
          Apply
        </Button>
        <Button variant='tertiary' onClick={onModalClose}>
          Cancel
        </Button>
        {isInProgress && (
          <UploadProgressStyled
            type={TypographyType.Body}
            variant={TypographyVariant.MediumRegular}
          >
            {`Uploading ${uploadProgress}%...`}
          </UploadProgressStyled>
        )}
      </FooterStyled>
    </UploadConfirmationDialogStyled>
  );
};
