import { createAction } from '@reduxjs/toolkit';
import { DisplayFieldsByType } from '../types';

export const CatalogExplorerDisplayPreferencesActions = {
  setDisplayPreferences: createAction<{
    shopId: number;
    catalogName: string;
    data: DisplayFieldsByType;
  }>('CatalogExplorerDisplayPreferences/SetPreferences'),
};
