import { DomainGroup, SubDomainGroupBase } from '../../types';
import { DomainEntityPath } from '../../../../../services';
import { AvailableIcons } from '../../../../../components-dummy';

export const collectionsTranslationsDomainGroup: DomainGroup & SubDomainGroupBase = {
  domainKey: DomainEntityPath.ProductTranslationsCollectionsTitles,
  title: 'Collections Translations',
  icon: AvailableIcons.Collections,
  getCardTitle: (): string => {
    return 'Collections > Translations';
  },
  fieldsSchema: {},
};
