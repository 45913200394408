import styled from '@emotion/styled';
import { Icon, Select, Spinner, Typography } from 'src/components-dummy';

/**
 * Page - Main title
 */
export const PageTitleWrapperStyled = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 40px;
  gap: 12px;
`;

export const PageMainTitleRowStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const PageMainTitleStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
  max-height: 27px;
`;

export const BackIconStyled = styled(Icon)`
  width: 10px;
  height: 10px;
  transform: rotate(180deg);
  margin-right: 20px;

  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-20']};
  }

  &:hover path {
    stroke: ${({ theme }) => theme.palette.common.black};
  }
`;

export const PrimaryCatalogStyled = styled.span`
  padding: 8px;
  border-radius: 2px;
  background: ${({ theme }) => theme.palette.custom['primary-light']};
  margin-left: 16px;
`;

export const TitleContextStyled = styled.span`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

/**
 * Page - Sub title
 */
export const PageSubTitleRowStyled = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  margin-left: 32px;
`;

export const SeparatorBulletStyled = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.custom['gray-60']};
  margin: 0 5px;
  align-self: center;
`;

export const InformationIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
  svg {
    & > path {
      fill: ${({ theme }) => theme.palette.custom['gray-10']};
      stroke: ${({ theme }) => theme.palette.custom.white};
    }
  }
`;

/**
 * Actions / settings / layout buttons
 */
export const ButtonsSectionStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

/**
 * Actions Menu
 */

export const ActionsMenuSelectStyled = styled(Select)`
  .MuiInputBase-input {
    padding: 8px 32px 8px 12px;
  }
`;

export const SpinnerStyled = styled(Spinner)`
  margin-left: 8px;
`;

/**
 * Display Preferences Button
 */
export const DisplayPreferencesButtonStyled = styled(Icon)`
  color: #6e6e71;
  cursor: pointer;
  margin-left: 20px;
`;

export const DisplayPreferencesButtonWrapperStyled = styled.div`
  display: flex;
  position: relative;
`;

/**
 * Sneak pick
 */
export const AISneakPickContentStyled = styled.div`
  display: inline-block;
`;

export const BlueTextTypographyStyled = styled(Typography)`
  color: #004599;
`;

export const AIPromoTagSneakPickWrapper = styled.div`
  position: absolute;
  right: 49px;
  bottom: -11px;
`;

export const ExportCSVConfirmationDialogWrapperStyled = styled.div`
  .MuiPaper-root {
    width: 520px;
  }
`;
