import React from 'react';
import { Tooltip } from 'src/components-dummy';
import { AddDataFields } from './AddDataFields';
import { UnselectedDataFieldOption } from './types';
import { DataFieldSelectSectionHeaderStyled } from './DataFieldsByTypeSections/DataFieldsByTypesSections.styles';
import { NO_ADDITIONAL_FIELDS_TEXT } from './CatalogExplorerDisplayPreferences.constants';

interface DataFieldsSelectHeaderProps {
  title?: string;
  unusedDataFieldsOptions: UnselectedDataFieldOption[];
  locale: string;
  onChange: (selectedItemValues: string[]) => void;
}

export const DataFieldsSelectHeader = React.memo(
  ({ unusedDataFieldsOptions, locale, onChange }: DataFieldsSelectHeaderProps) => {
    const isEnabled = !!unusedDataFieldsOptions.length;

    return (
      <DataFieldSelectSectionHeaderStyled>
        <Tooltip value={NO_ADDITIONAL_FIELDS_TEXT} position='top center' disabled={isEnabled}>
          <AddDataFields
            onChange={onChange}
            isDisabled={!isEnabled}
            filterOptions={unusedDataFieldsOptions}
            locale={locale}
          />
        </Tooltip>
      </DataFieldSelectSectionHeaderStyled>
    );
  }
);
