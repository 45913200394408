import styled from '@emotion/styled';

export const CollectionsHeaderWrapper = styled.div`
  &&& {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    width: 100%;
    gap: 16px;
  }
`;

export const CollectionTitle = styled.h1`
  &&& {
    flex: 1;
    font-size: 24px;
    font-weight: bold;
  }
`;
