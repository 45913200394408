import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldType, ShopDataField } from 'src/services';
import { DisplayedFieldItem, UnselectedDataFieldOption } from './types';
import { ReOrderedList } from 'src/components-dummy';
import { getLanguageCodeByLocale, sortArrayByLocale } from 'src/utils';
import { createFormItem } from './CatalogExplorerDisplayPreferences.helpers';

interface UseManageDisplayedFields {
  fieldType: FieldType;
  dataFieldsAPI: ShopDataField[];
  displayFieldsPreferences: DisplayedFieldItem[];
  locale: string;
  mandatoryInitialFields?: string[];
}

interface UseManageDisplayedFieldsReturn {
  displayedFormList: DisplayedFieldItem[];
  unselectedDataFieldOptions: UnselectedDataFieldOption[];
  isDirty: boolean;
  onItemValueChange: ({
    id,
    option,
  }: {
    id: string;
    option: UnselectedDataFieldOption['value'];
  }) => void;
  onItemRemove: (id: string) => void;
  onAddNewItems: (selectedValues: string[]) => void;
  onListOrderChange: (newList: ReOrderedList) => void;
}

interface FormState {
  displayedFormList: DisplayedFieldItem[];
  isDirty: boolean;
}

export const useManageDisplayedFields = ({
  fieldType,
  dataFieldsAPI,
  displayFieldsPreferences,
  locale,
}: UseManageDisplayedFields): UseManageDisplayedFieldsReturn => {
  const [formState, setFormState] = useState<FormState>({
    displayedFormList: [],
    isDirty: false,
  });

  const apiDataFieldsMapByName: Record<string, ShopDataField> = useMemo(() => {
    return dataFieldsAPI.reduce((prev, next) => {
      prev[next.name] = next;
      return prev;
    }, {});
  }, [dataFieldsAPI]);

  const onItemRemove = useCallback((id: string) => {
    setFormState(prevState => {
      const filteredList = prevState.displayedFormList.filter(item => item.id !== id);

      return {
        ...prevState,
        displayedFormList: filteredList,
        isDirty: true,
      };
    });
  }, []);

  const onItemValueChange = useCallback(
    ({ id, option }: { id: string; option: UnselectedDataFieldOption['value'] }) => {
      setFormState(prevState => {
        const { displayName: text, name: value } = apiDataFieldsMapByName[option];

        const updatedList = prevState.displayedFormList.map(item => {
          return item.id === id
            ? {
                ...item,
                value,
                text,
              }
            : item;
        });

        return {
          ...prevState,
          displayedFormList: updatedList,
          isDirty: true,
        };
      });
    },
    []
  );

  const onAddNewItems = useCallback((selectedValues: string[]) => {
    setFormState(prevState => {
      let newItemIndex = prevState.displayedFormList.length - 1;

      const newFormItems = selectedValues.map(selectedValue => {
        const apiDataField = apiDataFieldsMapByName[selectedValue];

        const newItem = createFormItem({
          fieldType,
          value: apiDataField.name,
          text: apiDataField.displayName,
          types: apiDataField.types,
          index: ++newItemIndex,
        });

        return newItem;
      });

      return {
        ...prevState,
        displayedFormList: prevState.displayedFormList.concat(newFormItems),
        isDirty: true,
      };
    });
  }, []);

  /**
   * Create initialy mandatory fields items state
   */
  useEffect(() => {
    const baseFieldsList = displayFieldsPreferences;
    setFormState({ displayedFormList: baseFieldsList, isDirty: false });
  }, [displayFieldsPreferences]);

  /**
   * Options list for selecting unselected values
   */
  const unselectedDataFieldsOptions: UnselectedDataFieldOption[] = useMemo(() => {
    const formItemValuesSet = new Set([...formState.displayedFormList.map(item => item.value)]);

    const optionsList = Object.keys(apiDataFieldsMapByName).reduce((prev, apiDataFieldName) => {
      const isInFormList = formItemValuesSet.has(apiDataFieldName);

      if (!isInFormList) {
        const { name, displayName } = apiDataFieldsMapByName[apiDataFieldName];
        prev.push({ value: name, text: displayName });
      }

      return prev;
    }, []);

    const sortedOptionsList = optionsList.sort((a, b) => {
      return sortArrayByLocale({
        a: a.text,
        b: b.text,
        languageCode: getLanguageCodeByLocale(locale || 'en_US'),
        isAscending: true,
      });
    });

    return sortedOptionsList;
  }, [formState.displayedFormList]);

  const onListOrderChange = useCallback((newOrderedList: ReOrderedList) => {
    setFormState(prevState => {
      const formItemByValueMap = prevState.displayedFormList.reduce(
        (prev, next): Record<string, DisplayedFieldItem> => {
          prev[next.value] = next;
          return prev;
        },
        {}
      );

      const updatedItemsPositions = newOrderedList.map(({ key }) => {
        return formItemByValueMap[key];
      });

      return {
        ...prevState,
        displayedFormList: updatedItemsPositions,
        isDirty: true,
      };
    });
  }, []);

  return {
    displayedFormList: formState.displayedFormList,
    unselectedDataFieldOptions: unselectedDataFieldsOptions,
    isDirty: formState.isDirty,
    onItemValueChange,
    onItemRemove,
    onAddNewItems,
    onListOrderChange,
  };
};
