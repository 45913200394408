import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const LastUserLocationWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  width: fit-content;
`;

export const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  size: 14px;
`;

export const LinkStyled = styled.span`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
`;
