import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { TextBox, TextBoxProps } from '../TextBox';
import { SearchInputStyled } from './SearchInput.styles';
import { Icon, AvailableIcons } from '../Icon';

export type SearchInputProps = TextBoxProps;

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (
    {
      value,
      placeholder,
      type,
      name,
      disabled,
      children,
      className,
      onChange,
      onKeyDown,
      onBlur,
      onFocus,
      searchIconName = AvailableIcons.Search,
      autoFocus,
      inputRef,
    },
    ref
  ): JSX.Element => {
    return (
      <SearchInputStyled className={classNames('syte-search-input', className)}>
        <TextBox
          value={value}
          type={type}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          autoComplete='off'
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={ref}
          autoFocus={autoFocus}
          inputRef={inputRef}
        >
          <>
            {children}
            <Icon name={searchIconName} className='search-icon' />
          </>
        </TextBox>
      </SearchInputStyled>
    );
  }
);
