import styled from '@emotion/styled';
import MuiMenu from '@mui/material/Menu';
import { MenuItem } from '../../../components-dummy';

export const MenuStyled = styled(MuiMenu)`
  .MuiPaper-root {
    width: 200px;
  }
`;

export const MenuItemStyled = styled(MenuItem)`
  gap: 12px;
  &.delete {
    span {
      color: ${({ theme }) => theme.palette.custom.red};
    }
    svg path {
      stroke: ${({ theme }) => theme.palette.custom.red};
    }
  }
`;
