import { useState, useCallback } from 'react';

export const useKeyboardNavigation = (
  options: { value: string; text: string }[],
  onOptionSelect: (value: string) => void
) => {
  const [focusedIndex, setFocusedIndex] = useState(-1);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();

        setFocusedIndex(prevIndex => Math.min(prevIndex + 1, options.length - 1));
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();

        setFocusedIndex(prevIndex => Math.max(prevIndex - 1, 0));
      } else if (event.key === 'Enter') {
        event.preventDefault();

        if (focusedIndex >= 0) {
          onOptionSelect(options[focusedIndex].value);
        }
      } else if (event.key === 'Escape') {
        setFocusedIndex(-1);
      }
    },
    [focusedIndex, options, onOptionSelect]
  );

  return {
    focusedIndex,
    setFocusedIndex,
    handleKeyDown,
  };
};
