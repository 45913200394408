import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { lastUserLocationActions } from './LastUserLocation.actions';
import { LastUserLocationWrapper, LinkStyled, TypographyStyled } from './LastUserLocation.styles';
import { Skeleton, TypographyType, TypographyVariant } from 'src/components-dummy';
import { baseAppRoutesPaths, RoutedComponentProps } from 'src/app-routes';
import { matchPath } from 'react-router';

export interface LastUserLocationProps extends RoutedComponentProps {
  dispatch: Dispatch;
  lastVisitedURL?: string;
}

export const LastUserLocation = ({
  dispatch,
  lastVisitedURL,
  permittedRouteMap,
}: LastUserLocationProps): JSX.Element | null => {
  const [isLoading, setIsLoading] = useState(false);

  const permittedRoutesMapByPath = useMemo(() => {
    return Object.values(permittedRouteMap).reduce((accumulator, current) => {
      accumulator[current.path] = current;

      return accumulator;
    }, {});
  }, [permittedRouteMap]);

  const targetRouteConfig = useMemo(() => {
    if (!lastVisitedURL) return;

    const targetPath = matchPath(lastVisitedURL, baseAppRoutesPaths);

    if (!targetPath) return;

    return permittedRoutesMapByPath[targetPath.path];
  }, [lastVisitedURL, permittedRoutesMapByPath]);

  const onClick = useCallback(() => {
    if (!lastVisitedURL) return;

    dispatch(lastUserLocationActions.navigateTo({ navigateTo: lastVisitedURL }));
  }, [dispatch, lastVisitedURL]);

  const fetchCurrentUser = useCallback(async () => {
    setIsLoading(true);

    try {
      (dispatch(lastUserLocationActions.getCurrentUser()) as any).unwrap();
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  if (isLoading) {
    return <Skeleton height={40} width={400} />;
  }

  if (!targetRouteConfig) {
    return null;
  }

  return (
    <LastUserLocationWrapper>
      <TypographyStyled variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
        Pick up
        {targetRouteConfig.title && (
          <>
            {' on '}
            <LinkStyled onClick={onClick}>{targetRouteConfig.title}</LinkStyled> - {''}
          </>
        )}
        where you last left off.
      </TypographyStyled>
    </LastUserLocationWrapper>
  );
};
