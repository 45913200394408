import React from 'react';
import { SubTitleTypographyStyled } from './SectionWithTitle.styles';
import { TypographyType, TypographyVariant } from 'src/components-dummy';

export interface SectionWithTitleProps {
  title?: string;
  children: JSX.Element | JSX.Element[];
}

export const SectionWithTitle = ({ title, children }: SectionWithTitleProps): JSX.Element => {
  return (
    <>
      <SubTitleTypographyStyled
        variant={TypographyVariant.LargeRegular}
        type={TypographyType.Paragraph}
      >
        {title}
      </SubTitleTypographyStyled>
      {children}
    </>
  );
};
