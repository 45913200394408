import React from 'react';
import { TableRow } from './TableRow';
import { useDynamicTableColumns } from './useDynamicTableColumns';
import { TableWrapperStyled } from './CatalogExplorerTable.styled';
import { TableV2 } from 'src/components-dummy';
import { ICatalogProduct } from 'src/services';
import { css } from '@emotion/react';
import { PaginationState } from '../../../../components-dummy/PaginationToolBar';
import { CatalogExplorerTableBodySkeleton } from './CatalogExplorerTableSkeleton';
import { DisplayedFieldItem } from '../CatalogExplorerDisplayPreferences/types';
import { DisplayFieldsByType } from '../types';

interface CatalogExplorerTableProps {
  dataFieldsMapByName: Record<string, DisplayedFieldItem>;
  displayFieldsPreferencesByType: DisplayFieldsByType;
  catalogProducts: ICatalogProduct[];
  paginationState: PaginationState;
  isPendingOnFetchProducts: boolean;
}

export function CatalogExplorerTable({
  dataFieldsMapByName,
  catalogProducts,
  paginationState,
  isPendingOnFetchProducts,
  displayFieldsPreferencesByType,
}: CatalogExplorerTableProps): JSX.Element {
  const { tableColumnsObject, tableColumnsOrderedList } = useDynamicTableColumns({
    displayFieldsPreferencesByType,
    displayFieldItemByName: dataFieldsMapByName,
  });

  return (
    <TableWrapperStyled>
      <TableV2<any>
        options={{ columns: tableColumnsOrderedList, data: catalogProducts }}
        customOptions={{
          shouldUseResizeColumns: true,
          shouldUseFlexLayout: true,
        }}
      >
        {({ getTableBodyProps, headerGroups, rows, prepareRow, state }) => {
          const headerGroup = headerGroups[0];

          const isResizingColumn = !!state.columnResizing.isResizingColumn;

          return (
            <>
              <TableV2.Head {...headerGroup.getHeaderGroupProps()} isSticky>
                {headerGroup.headers.map(column => (
                  <TableV2.HeadRow {...column.getHeaderProps()} isResizable key={column.id}>
                    <TableV2.HeadRowCellText key={`${isResizingColumn}`}>
                      {column.render('header')}
                    </TableV2.HeadRowCellText>
                    {(tableColumnsObject as Record<string, any>)[column.id].canResize ===
                    false ? null : (
                      <TableV2.HeaderRowCellResizableCursor column={column} />
                    )}
                  </TableV2.HeadRow>
                ))}
              </TableV2.Head>
              <TableV2.Body
                {...getTableBodyProps()}
                css={css`
                  overflow-y: auto;
                  scrollbar-gutter: auto;
                `}
              >
                {isPendingOnFetchProducts ? (
                  <CatalogExplorerTableBodySkeleton />
                ) : !rows?.length ? (
                  <TableV2.NoResults width={291} />
                ) : (
                  rows.map((row, index) => {
                    prepareRow(row);

                    const rowNumber = paginationState.skip + (index + 1);

                    return (
                      <TableRow
                        key={row.original._id}
                        rowNumber={rowNumber}
                        isResizingColumn={isResizingColumn}
                        row={row}
                        tableColumns={tableColumnsObject}
                      />
                    );
                  })
                )}
              </TableV2.Body>
            </>
          );
        }}
      </TableV2>
    </TableWrapperStyled>
  );
}
