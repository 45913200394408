import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CatalogExplorerFilterAutoComplete } from '../CatalogExplorerFilterAutoComplete/CatalogExplorerFilterAutoComplete';
import { Dispatch } from 'src/components-bl/types';
import {
  FiltersHeaderStyled,
  FiltersHeaderMainStyled,
  CatalogExplorerFiltersPanelStyled,
  CatalogExplorerFiltersStyled,
  FiltersListItemsStyled,
  FiltersHeaderDetailsStyled,
} from './CatalogExplorerFilters.styles';
import {
  Button,
  EllipsisWithTooltip,
  MultiSelectOption,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import {
  addSeparatorsToNumber,
  getItemDisplayText,
  isAcceptedDataFieldForFilters,
} from '../CatalogExplorer.helpers';
import { CatalogExplorerAddFilter } from './CatalogExplorerAddFilter';
import { CatalogExplorerSearchInput } from './CatalogExplorerSearchInput';
import { DisplayedFieldItem } from '../CatalogExplorerDisplayPreferences/types';
import { filterListIfChanged } from './CatalogExplorerFilters.helpers';

const MAIN_SEARCH = 'main_search';
const ALL = 'all';

interface CatalogExplorerFiltersProps {
  shopId: number;
  catalogName: string;
  displayFieldsMap: Record<string, DisplayedFieldItem>;
  totalFilteredProducts: number;
  filters: Record<string, string[] | number[]>;
  mainSearchFilterTerm?: string;
  locale: string;
  dispatch: Dispatch;
  onFiltersStateChange: (partialUpdate: Record<string, string[] | number[]>) => void;
  onMainSearchFilterChange: (value: string) => void;
  onClearAllFilters: () => void;
}

export const CatalogExplorerFilters = React.memo(
  ({
    shopId,
    catalogName,
    totalFilteredProducts,
    displayFieldsMap,
    filters,
    mainSearchFilterTerm,
    locale,
    onFiltersStateChange,
    onMainSearchFilterChange,
    onClearAllFilters,
  }: CatalogExplorerFiltersProps) => {
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
    const [searchText, setSearchText] = useState('');
    const lastChangeOnFilterType = useRef('');

    const updateLastChangeOnFilterType = filterKey => {
      lastChangeOnFilterType.current = filterKey;
    };

    const handleFiltersStateChange = useCallback(
      (field: string) => (values: string[]) => {
        updateLastChangeOnFilterType(field);
        onFiltersStateChange({ [field]: values });
      },
      []
    );

    const hasSomeActiveFilter = useMemo(() => {
      const hasActiveFilter = Object.keys(filters).some(filterKey => filters[filterKey].length > 0);
      const hasSearchTermActive = searchText.trim().length > 0;

      return hasActiveFilter || hasSearchTermActive;
    }, [filters, searchText]);

    const onFilterTypesSelection = useCallback(
      (selectedFilterTypeKeys: string[]) => {
        setSelectedFilters(selectedFilterTypeKeys);
        const filterTypesSet = new Set(selectedFilterTypeKeys);

        // Clear unselected filter types
        Object.keys(filters).forEach(filterKey => {
          if (!filterTypesSet.has(filterKey)) {
            updateLastChangeOnFilterType(filterKey);
            onFiltersStateChange({ [filterKey]: [] });
          }
        }, {});
      },
      [filters]
    );

    const filterTypeOptions = useMemo(() => {
      return Object.keys(displayFieldsMap).reduce((prev, itemKey) => {
        const { value, text, fieldType, types } = displayFieldsMap[itemKey];
        const isAcceptedForFilters = isAcceptedDataFieldForFilters({ dataFieldTypes: types });

        if (isAcceptedForFilters) {
          const option = {
            value,
            text: getItemDisplayText({ value, text, fieldType, shouldAddAITagsText: true }),
          };
          prev.push(option);
        }

        return prev;
      }, [] as MultiSelectOption[]);
    }, [displayFieldsMap]);

    const onRemoveFilter = useCallback((filterKey: string) => {
      updateLastChangeOnFilterType(filterKey);

      // 1. Unselect from selectedTypes (revealed filter types list)
      setSelectedFilters(prevState => {
        return prevState.filter(selected => selected !== filterKey);
      });

      // 2. Clear selected filters state for filter:
      onFiltersStateChange({ [filterKey]: [] });
    }, []);

    const onClearSearchText = useCallback(() => {
      updateLastChangeOnFilterType(MAIN_SEARCH);

      setSearchText('');
      onMainSearchFilterChange('');
    }, []);

    const onSearchTermChange = useCallback((inputValue: string) => {
      updateLastChangeOnFilterType(MAIN_SEARCH);
      setSearchText(inputValue);
      onMainSearchFilterChange(inputValue.trim());
    }, []);

    const handleClearAllFilters = useCallback(() => {
      updateLastChangeOnFilterType(ALL);
      setSearchText('');
      onClearAllFilters();
    }, []);

    /**
     * Remove from filters - irrelevant fields (changes in display preferences config)
     */
    useEffect(() => {
      setSelectedFilters(prevState => {
        return filterListIfChanged({ currentList: prevState, displayFieldsMap });
      });
    }, [displayFieldsMap]);

    const relevantSelectedFilters = useMemo(() => {
      return filterListIfChanged({ currentList: selectedFilters, displayFieldsMap });
    }, [displayFieldsMap, selectedFilters]);

    const totalFilteredProductsText = addSeparatorsToNumber(totalFilteredProducts);

    return (
      <CatalogExplorerFiltersPanelStyled>
        <FiltersHeaderStyled>
          <FiltersHeaderMainStyled>
            <CatalogExplorerSearchInput
              onClear={onClearSearchText}
              onChange={onSearchTermChange}
              value={searchText}
            />
            <CatalogExplorerAddFilter
              onChange={onFilterTypesSelection}
              filterOptions={filterTypeOptions}
              selectedFilterKeys={relevantSelectedFilters}
              locale={locale}
            />
          </FiltersHeaderMainStyled>
          <FiltersHeaderDetailsStyled>
            <Button
              onClick={handleClearAllFilters}
              size='tiny'
              variant='primary'
              disabled={!hasSomeActiveFilter}
            >
              Clear All
            </Button>
            <EllipsisWithTooltip tooltipText={totalFilteredProductsText}>
              <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                {totalFilteredProductsText} Results
              </Typography>
            </EllipsisWithTooltip>
          </FiltersHeaderDetailsStyled>
        </FiltersHeaderStyled>
        {relevantSelectedFilters.length > 0 && (
          <CatalogExplorerFiltersStyled>
            <FiltersListItemsStyled>
              {relevantSelectedFilters.map(field => {
                const fieldDisplayName = getItemDisplayText({
                  fieldType: displayFieldsMap[field].fieldType,
                  value: displayFieldsMap[field].value,
                  text: displayFieldsMap[field].text,
                  shouldAddAITagsText: true,
                });

                return (
                  <CatalogExplorerFilterAutoComplete
                    key={field}
                    onChange={handleFiltersStateChange(field)}
                    onRemoveFilter={onRemoveFilter}
                    fieldDisplayName={fieldDisplayName}
                    lastChangeOnFilterType={lastChangeOnFilterType.current}
                    shopId={shopId}
                    catalogName={catalogName}
                    fieldDataTypes={displayFieldsMap[field].types}
                    field={field}
                    filters={filters}
                    mainSearchFilterTerm={mainSearchFilterTerm}
                  />
                );
              })}
            </FiltersListItemsStyled>
          </CatalogExplorerFiltersStyled>
        )}
      </CatalogExplorerFiltersPanelStyled>
    );
  }
);
