import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon, Spinner, TagsInput } from 'src/components-dummy';

export const TagsInputStyled = styled(TagsInput)<{ hideInput: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid transparent;
  padding: 0;
  gap: 4px;

  background: ${({ theme }) => theme.palette.custom['gray-90']};

  .syte-tags-input {
    height: 20px;
    margin-left: 8px;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &:focus,
  &:focus-within {
    border-color: ${({ theme }) => theme.palette.custom['primary-main']} !important;
  }

  input {
    padding-left: 8px;
    width: 95px;
    ${({ hideInput }) =>
      hideInput &&
      css`
        display: none;
      `};

    cursor: pointer;
    pointer-events: none;

    &::placeholder {
      font-size: 12px;
      color: ${({ theme, disabled }) =>
        disabled ? theme.palette.custom['gray-20'] : theme.palette.custom['gray-30']};
    }
  }

  .tag-label {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.custom.white};
    padding: 8px 12px;
    margin: 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    max-width: 250px;
  }

  .additional-items-tag {
    background-color: unset;
  }
`;

export const SpinnerStyled = styled(Spinner)`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const MultiSelectMenuWithSearchWrapperStyled = styled.div`
  ul {
    overflow-y: auto !important;
  }
`;

export const MultiSelectMenuWithVirtualScrollWrapperStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 167px;
`;

export const CatalogExplorerFilterAutoCompleteStyled = styled.div`
  width: fit-content;
  display: inline-flex;
  align-items: center;
  gap: 8px;

  .syte-input-base {
    height: 24px;
    min-height: 24px;
    border-radius: 4px;
  }
  .syte-tags-input {
    height: 24px;
    .input {
      height: 24px;
    }
  }
  .syte-popup-trigger {
    width: fit-content;
  }
`;

export const FilterItemReadOnlyWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  border: ${({ theme }) => `1px solid ${theme.palette.custom['gray-60']}`};
  background: ${({ theme }) => theme.palette.custom['gray-90']};
  border-radius: 4px;
  padding: 4px 12px;
  gap: 8px;

  & > span:first-of-type {
    margin-right: 8px;
  }
`;

export const IntroFilterTextStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DeleteButtonWrapperStyled = styled.div`
  display: flex;
  width: 20px;
  align-items: end;
  margin: 8px;
  cursor: pointer;
`;

export const IconTrashStyled = styled(Icon)`
  width: 16px;
  height: 16px;
  path {
    stroke: ${({ theme }) => theme.palette.common.black};
  }
`;
