import React from 'react';
import { Row } from 'react-table';
import {
  ImageWrapperStyled,
  ProductNumberStyled,
  TableBodyRowCellAlignedCenterStyled,
  TableBodyRowCellStyled,
} from './CatalogExplorerTable.styled';
import { TableV2 } from 'src/components-dummy';
import { TableHeadRow } from './CatalogExplorerTable.types';
import { ImagePopup } from './ImagePopup';
import { addSeparatorsToNumber } from '../CatalogExplorer.helpers';

const NO_VALUE = '-';

type RowData = Record<string, any>;

interface TableRowProps {
  row: Row<RowData>;
  tableColumns: Record<string, TableHeadRow>;
  rowNumber: number;
  isResizingColumn: boolean;
}

export const TableRow = ({
  row,
  tableColumns,
  rowNumber,
  isResizingColumn,
}: TableRowProps): JSX.Element => {
  return (
    <>
      <TableV2.BodyRow {...row.getRowProps()} key={row.original.id}>
        {row.cells.map(cell => {
          const propName = cell.column.id;

          if (propName === tableColumns.index.accessor) {
            return (
              <TableBodyRowCellAlignedCenterStyled
                {...cell.getCellProps()}
                key={`${propName}-${isResizingColumn}`}
                width={cell.column.width as string}
                minWidth={cell.column.minWidth}
              >
                <ProductNumberStyled>
                  <TableV2.BodyRowCellText>
                    {addSeparatorsToNumber(rowNumber)}
                  </TableV2.BodyRowCellText>
                </ProductNumberStyled>
              </TableBodyRowCellAlignedCenterStyled>
            );
          }

          if (propName === tableColumns?.imageUrl?.accessor) {
            return (
              <TableBodyRowCellStyled
                {...cell.getCellProps()}
                key={`${propName}-${isResizingColumn}`}
                width={cell.column.width as string}
                minWidth={cell.column.minWidth}
              >
                <ImageWrapperStyled>
                  <ImagePopup src={cell.value} alt='product' />
                </ImageWrapperStyled>
              </TableBodyRowCellStyled>
            );
          }

          const cellValue = cell.value || row.original[propName];
          const cellDisplayValue = Array.isArray(cellValue)
            ? cellValue.join(', ')
            : `${cellValue || NO_VALUE}`;

          return (
            <TableBodyRowCellStyled
              {...cell.getCellProps()}
              key={`${propName}-${isResizingColumn}`}
              width={cell.column.width as string}
              minWidth={cell.column.minWidth}
            >
              <TableV2.BodyRowCellText>{cellDisplayValue}</TableV2.BodyRowCellText>
            </TableBodyRowCellStyled>
          );
        })}
      </TableV2.BodyRow>
    </>
  );
};
