import React, { memo, useEffect } from 'react';
import { useAppSelector } from '../../../../hooks';
import { IntersectionObserverHookRefCallback } from '../../../../hooks/useIntersectionObserver';
import { Spinner } from '../../../../components-dummy';
import { VisualEditorResultsToolbar } from './VisualEditorResultsToolbar';
import { GridLayoutContainer } from './GridLayout/GridLayoutContainer';
import { ListLayout } from './ListLayout';
import { IndexedOffers, PreviewMerchRule } from '../../types';
import { UpdateMerchRuleArgs } from '../../../MerchandisingRules/components/MerchandisingRuleForm';
import { Layout } from '../../consts/layout';
import {
  LoaderContainer,
  VisualEditorContentResults,
  VisualEditorResults,
} from './VisualEditorResultsScreen.styles';

export interface ResultsScreenProps {
  shopId: number;
  offers: IndexedOffers;
  onRefresh?: VoidFunction;
  itemsCount: number;
  totalItemsCount: number;
  disableRefresh: boolean;
  infiniteScroll?: {
    showLoader: boolean;
    rootRef: IntersectionObserverHookRefCallback;
    sentryRef: IntersectionObserverHookRefCallback;
  };
  selectedRule: PreviewMerchRule | null;
  submitStrategyForm: (payload: UpdateMerchRuleArgs) => void;
  layout: Layout;
  onLayoutChange: (view: Layout) => void;
  shouldDisplayItemsCount: boolean;
  resultsScreenRef: React.MutableRefObject<HTMLDivElement>;
  openProductDetailsModal: (sku?: string) => void;
  shouldDisplaySettings: boolean;
}

export const ResultsScreen = memo(
  ({
    shopId,
    offers,
    onRefresh,
    itemsCount,
    totalItemsCount,
    disableRefresh,
    infiniteScroll,
    selectedRule,
    submitStrategyForm,
    layout,
    onLayoutChange,
    shouldDisplayItemsCount,
    resultsScreenRef,
    openProductDetailsModal,
    shouldDisplaySettings,
  }: ResultsScreenProps): JSX.Element => {
    const { dataFieldsToDisplay, specialDataFieldsToDisplay, uniqueByField, itemsCountPerRow } =
      useAppSelector(state => state.visualEditor);
    const { sentryRef, rootRef, showLoader } = infiniteScroll || {};

    useEffect(() => {
      onRefresh();
    }, [onRefresh]);

    return (
      <VisualEditorContentResults>
        <VisualEditorResultsToolbar
          shopId={shopId}
          layout={layout}
          onRefresh={onRefresh}
          itemsCount={itemsCount}
          totalItemsCount={totalItemsCount}
          disabled={disableRefresh}
          onLayoutChange={onLayoutChange}
          shouldDisplayItemsCount={shouldDisplayItemsCount}
          shouldDisplaySettings={shouldDisplaySettings}
        />
        <VisualEditorResults
          ref={el => {
            resultsScreenRef.current = el;
            if (typeof rootRef === 'function') {
              rootRef(el);
            }
          }}
        >
          {layout === Layout.GRID ? (
            <GridLayoutContainer
              offers={offers}
              dataFieldsToDisplay={dataFieldsToDisplay}
              specialDataFieldsToDisplay={specialDataFieldsToDisplay}
              itemsCountPerRow={itemsCountPerRow}
              selectedRule={selectedRule}
              submitStrategyForm={submitStrategyForm}
              shopId={shopId}
              uniqueByField={uniqueByField}
              openProductDetailsModal={openProductDetailsModal}
            />
          ) : (
            <ListLayout
              offers={offers}
              dataFieldsToDisplay={dataFieldsToDisplay}
              specialDataFieldsToDisplay={specialDataFieldsToDisplay}
              openProductDetailsModal={openProductDetailsModal}
            />
          )}
          {showLoader && (
            <LoaderContainer ref={sentryRef}>
              <Spinner />
            </LoaderContainer>
          )}
        </VisualEditorResults>
      </VisualEditorContentResults>
    );
  }
);
