import React from 'react';
import { generatePath } from 'react-router-dom';
import { ILocationUser, systemUser } from 'src/services';
import { PopUp } from 'src/components-dummy';
import { Dispatch } from 'src/components-bl/types';
import { profileMenuActionMethods } from 'src/components-bl/ProfileMenu/Actions';
import {
  ProfileNameTooltipStyled,
  UserProfileThumbnailStyled,
  SytePopUpMenuContentStyled,
  PopUpMenuItemStyled,
} from '../UserAvatarList.styles';
import { headerUserThumbnailSize } from '../constants';

export interface IUserThumbnailElementProps {
  user?: ILocationUser;
  enableTooltip?: boolean;
  isOnline?: boolean;
  myProfilePath: string | undefined;
  dispatch: Dispatch;
  isMenuOpen: boolean;
  setIsMenuOpen?: (isMenuOpen: boolean) => void;
}

export function getUserDisplayName({
  firstName,
  lastName,
  email,
}: Pick<ILocationUser, 'firstName' | 'lastName' | 'email'>): string {
  if (firstName.length > 0 && lastName.length > 0) {
    return `${firstName} ${lastName}`;
  }

  if (firstName.length > 0) {
    return firstName;
  }

  if (lastName.length > 0) {
    return lastName;
  }

  return email;
}

export const UserThumbnailElement = ({
  user,
  enableTooltip = true,
  isOnline,
  dispatch,
  myProfilePath,
  isMenuOpen,
  setIsMenuOpen,
}: IUserThumbnailElementProps): JSX.Element | null => {
  const onMyProfileClick = () => {
    setIsMenuOpen?.(false);
    dispatch(profileMenuActionMethods.navigateTo(generatePath(myProfilePath ?? '')));
  };

  const onLogoutClick = () => {
    dispatch(profileMenuActionMethods.logOut());
  };

  if (!user) {
    return null;
  }

  const displayName = getUserDisplayName(user);

  let tooltipValue = `${displayName}`;

  if (user.isMe) {
    tooltipValue = `${tooltipValue} (${isOnline ? 'online' : 'offline'})`;
  }

  if (user.isEditing) {
    tooltipValue = `${tooltipValue} - Is in edit mode`;
  }

  let isOnlineIndicator;
  if (user.isMe) {
    isOnlineIndicator = isOnline;
  }

  return (
    <ProfileNameTooltipStyled
      value={tooltipValue}
      hoverable
      disabled={isMenuOpen || !enableTooltip}
      position='bottom left'
    >
      {user.isMe ? (
        <PopUp
          position='bottom left'
          closeOnContentClick
          onShow={setIsMenuOpen}
          show={isMenuOpen}
          contentClickPreventDefault={false}
          className='syte-popup-menu'
          disabled={!setIsMenuOpen}
        >
          <PopUp.Trigger className='syte-popup-menu-wrapper'>
            <UserProfileThumbnailStyled
              displayName={displayName}
              isEditing={user.isEditing}
              thumbnailSize={headerUserThumbnailSize}
              online={isOnlineIndicator}
              showTooltip={false}
              isSystemUser={user.userId === systemUser.userId}
            />
          </PopUp.Trigger>
          <PopUp.Content>
            <SytePopUpMenuContentStyled className='syte-popup-menu-content'>
              <PopUpMenuItemStyled onClick={onMyProfileClick}>My Profile</PopUpMenuItemStyled>
              <PopUpMenuItemStyled onClick={onLogoutClick}>Log Out</PopUpMenuItemStyled>
            </SytePopUpMenuContentStyled>
          </PopUp.Content>
        </PopUp>
      ) : (
        <UserProfileThumbnailStyled
          displayName={displayName}
          isEditing={user.isEditing}
          thumbnailSize={headerUserThumbnailSize}
          online={isOnlineIndicator}
          showTooltip={false}
          isSystemUser={user.userId === systemUser.userId}
        />
      )}
    </ProfileNameTooltipStyled>
  );
};
