import React, { useMemo } from 'react';
import { startCase, flatten } from 'lodash';
import { format } from 'date-fns';
import { TableV2 } from 'src/components-dummy';
import { IShopCatalog } from 'src/services';
import { tableColumns } from './tableColumns';
import { CatalogType, ICatalogTableBodyRow } from './types';
import { CatalogTableRow } from './CatalogTableRow';
import { TableWrapperStyled } from './CatalogTableRow.styles';

interface CatalogsListProps {
  catalogs: IShopCatalog[];
  onSetCatalogAsPrimary: (catalogId: string) => void;
  onCatalogDelete: (catalogId: string) => void;
  navigateToEditCatalog: (catalogId: string) => void;
  navigateToFeedUploadLog: (catalogId: string) => void;
  navigateToViewCatalog: (catalogId: string) => void;
  onCatalogReconnect: (catalogId: string) => void;
  readOnly: boolean;
}

const DATE_FORMAT = 'HH:mm dd/MM/yy';

function getCatalogsThatCanBeSetAsPrimary(catalogs: IShopCatalog[]): Set<string> {
  const result = new Set<string>();

  const primaryCatalogs = catalogs.filter(catalog => catalog.isPrimary);

  const primaryAugmentedCatalogs = primaryCatalogs.filter(
    catalog => catalog.isAugmentedSearchCatalog
  );

  const verticalsFromPrimaryCatalogs = new Set(
    flatten(primaryCatalogs.map(catalog => catalog.verticals))
  );

  catalogs.forEach(catalog => {
    const catalogDoesNotHaveOverlappingVerticals = catalog.verticals.every(
      catalogVertical => !verticalsFromPrimaryCatalogs.has(catalogVertical)
    );

    if (catalogDoesNotHaveOverlappingVerticals) {
      if (!catalog.isAugmentedSearchCatalog) {
        result.add(catalog.id);
      } else if (primaryAugmentedCatalogs.length === 0) {
        result.add(catalog.id);
      }
    }
  });

  return result;
}

export const CatalogsList = ({
  catalogs,
  onSetCatalogAsPrimary,
  onCatalogDelete,
  navigateToEditCatalog,
  navigateToFeedUploadLog,
  navigateToViewCatalog,
  onCatalogReconnect,
  readOnly,
}: CatalogsListProps): JSX.Element => {
  const tableData: ICatalogTableBodyRow[] = useMemo(() => {
    const canBePrimaryCatalogIdsSet = getCatalogsThatCanBeSetAsPrimary(catalogs);

    return catalogs.map(catalog => {
      const {
        id,
        name,
        lastUpdated,
        isPrimary,
        verticals,
        isAugmentedSearchCatalog,
        numberOfIndexedProducts,
        addedMethod,
      } = catalog;
      const verticalsText = `${verticals.map(vertical => startCase(vertical)).join(',')}${
        isAugmentedSearchCatalog ? ' (Text Search)' : ''
      }`;

      const formattedLastUpdated = lastUpdated ? format(lastUpdated, DATE_FORMAT) : '';

      return {
        id,
        name,
        addedMethod,
        lastUpdated: formattedLastUpdated,
        isPrimary: isPrimary ? CatalogType.Primary : CatalogType.NonPrimary,
        numberOfIndexedProducts: numberOfIndexedProducts?.toLocaleString(),
        vertical: verticalsText,
        rowActions: true,
        shouldShowAlert: !lastUpdated,
        originalCatalog: catalog,
        canBeSetAsPrimary: canBePrimaryCatalogIdsSet.has(catalog.id),
      };
    });
  }, [catalogs]);

  const columnsArr = useMemo(() => Object.values(tableColumns), [tableColumns]);

  return (
    <TableWrapperStyled>
      <TableV2<ICatalogTableBodyRow>
        options={{ columns: columnsArr, data: tableData }}
        customOptions={{
          shouldUseResizeColumns: true,
          shouldUseFlexLayout: true,
        }}
      >
        {({ getTableBodyProps, headerGroups, rows, prepareRow, state }) => {
          const headerGroup = headerGroups[0];

          const isResizingColumn = !!state.columnResizing.isResizingColumn;

          return (
            <>
              <TableV2.Head {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableV2.HeadRow {...column.getHeaderProps()} isResizable key={column.id}>
                    <TableV2.HeadRowCellText key={`${isResizingColumn}`}>
                      {column.render('Header')}
                    </TableV2.HeadRowCellText>
                    {tableColumns[column.id].canResize === false ? null : (
                      <TableV2.HeaderRowCellResizableCursor column={column} />
                    )}
                  </TableV2.HeadRow>
                ))}
              </TableV2.Head>
              <TableV2.Body
                {...getTableBodyProps()}
                css={{
                  overflow: 'initial',
                }}
              >
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <CatalogTableRow
                      key={row.original.id}
                      row={row}
                      onSetCatalogAsPrimary={onSetCatalogAsPrimary}
                      onCatalogDelete={onCatalogDelete}
                      navigateToEditCatalog={navigateToEditCatalog}
                      navigateToFeedUploadLog={navigateToFeedUploadLog}
                      navigateToViewCatalog={navigateToViewCatalog}
                      onCatalogReconnect={onCatalogReconnect}
                      lastUpdated={row.original.lastUpdated}
                      readOnly={readOnly}
                    />
                  );
                })}
              </TableV2.Body>
            </>
          );
        }}
      </TableV2>
    </TableWrapperStyled>
  );
};
