import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { lexiconSharedActionCreators } from 'src/containers/Lexicon/shared-actions/lexicon-shared-actions';
import { dataFieldsService, FieldType } from 'src/services';

export const catalogExplorerContainerActions = {
  navigateTo: createAction<{ navigateTo: string }>('CatalogFormContainer/NavigateTo'),
  getCatalogDataFields: createAsyncThunk(
    'CatalogExplorerContainerActions/GetCatalogDataFields',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const dataFields = await dataFieldsService.getDataFields({
          shopId,
          fieldType: FieldType.CatalogField,
        });
        return { dataFields };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getAITagsDataFields: createAsyncThunk(
    'CatalogExplorerContainerActions/GetAITagsDataFields',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const dataFields = await dataFieldsService.getDataFields({
          shopId,
          fieldType: FieldType.AiTag,
        });
        return { dataFields };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  getLexiconsMetadataList: lexiconSharedActionCreators.getLexiconMetadata(
    'CatalogExplorerContainerActions/GetLexiconsMetadataList'
  ),
};
