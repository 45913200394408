import React from 'react';
import { SideNavGroups, ISideNavItem } from '../types';

import { SideNavListItem } from './SideNavListItem';

interface ISideNavGroup {
  group: SideNavGroups;
  routes: ISideNavItem[];
  isMinimized: boolean;
  handleNavigate: (url: string) => void;
  setMinimized: () => void;
}

export const SideNavGroup = ({
  group,
  routes,
  isMinimized,
  handleNavigate,
  setMinimized,
}: ISideNavGroup) => {
  const shouldRenderGroupName = (() => {
    if (isMinimized) return false;
    if ([SideNavGroups.ShopSettings, SideNavGroups.Home].includes(group)) return false;

    const isAnyRouteInGroup = routes.some(route => group === route.group);
    return isAnyRouteInGroup;
  })();

  return (
    <div
      className={
        group === SideNavGroups.ShopSettings
          ? 'syte-side-navigation-items-list-group-shop-settings'
          : 'syte-side-navigation-items-list-group'
      }
    >
      {shouldRenderGroupName && (
        <div className='syte-side-navigation-items-list-group-name'>{group}</div>
      )}
      {routes.map(
        route =>
          route.group === group && (
            <SideNavListItem
              route={route}
              key={route.title}
              isMinimized={isMinimized}
              handleNavigate={handleNavigate}
              setMinimized={setMinimized}
            />
          )
      )}
    </div>
  );
};
