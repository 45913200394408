import styled from '@emotion/styled';
import {
  DialogModal,
  DialogModalContent,
  DialogModalFooter,
} from 'src/components-dummy/DialogModal';

export const DialogModalStyled = styled(DialogModal)`
  .MuiPaper-root {
    max-width: 520px;
  }
`;

export const DialogModalFooterStyled = styled(DialogModalFooter)`
  gap: 5px;
`;

/**
 * DialogModalContent
 */
export const DialogModalContentStyled = styled(DialogModalContent)`
  gap: 24px;
`;

/**
 * AddDataFieldToDisplay
 */
export const AddTagButtonWrapperStyled = styled.div<{
  isClickable?: boolean;
  clicked?: boolean;
  isDisabled: boolean;
}>`
  button {
    color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.palette.custom['gray-100'] : theme.palette.custom['primary-main']};
    span {
      display: flex;
      align-items: center;
    }
    svg > path {
      stroke: ${({ theme, isDisabled }) =>
        isDisabled ? 'rgba(0, 0, 0, 0.26)' : theme.palette.custom['primary-main']};
    }
  }

  background-color: ${({ theme, isClickable, clicked }) => {
    if (isClickable && clicked) {
      return theme.palette.custom['primary-90'];
    }
    return theme.palette.custom.white;
  }};

  transition: background-color 0.1s;
  user-select: none;

  ${({ isClickable }) => (isClickable ? 'cursor: pointer' : '')}

  &:hover {
    background-color: ${({ theme, isDisabled }) => {
      return isDisabled ? 'initial' : theme.palette.custom['primary-90'];
    }};
  }
`;

export const AddDataFieldsContentStyled = styled.div`
  .syte-card-item {
    width: 353px;
  }
`;
