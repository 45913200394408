import React from 'react';
import styled from '@emotion/styled';
import { Button, ButtonProps, CardItem } from 'src/components-dummy';

export const CatalogExplorerFiltersPanelStyled = styled(CardItem)`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  margin-bottom: 24px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  width: fit-content;
  min-width: 100%;
  box-shadow: none;
`;

export const CatalogExplorerFiltersStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
  margin-top: 16px;
`;

export const FiltersHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const FiltersHeaderMainStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
`;

export const FiltersHeaderDetailsStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
`;

export const FiltersListItemsStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const AddFilterButtonStyled = styled((props: ButtonProps) => <Button {...props} />)<{
  clicked?: boolean;
}>`
  color: ${({ theme }) => theme.palette.common.black};
  border: unset !important ;
  background-color: ${({ theme, clicked }) =>
    clicked ? theme.palette.custom['primary-90'] : theme.palette.common.white};

  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom['primary-90']};
  }
`;

export const AddFilterTitle = styled.div`
  display: flex;
  padding: 12px 12px 8px 8px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`;

export const SearchInputWrapperStyled = styled.div`
  width: 240px;
  position: relative;
  .input-base-wrapper {
    display: flex;
    align-items: center;
    padding: 8px;
    padding-right: 27px;
    background: ${({ theme }) => theme.palette.custom['gray-90']};
    height: 32px;

    input {
      font-size: 12px;
    }
  }

  .clear-button {
    position: absolute;
    right: 10px;
    top: 8px;
    width: 10px;
    height: 10x;
    cursor: pointer;
  }
`;

/**
 * Add Filter
 */

export const MultiSelectWrapperStyled = styled(CardItem)<{ width?: number }>`
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  width: ${({ width }) => `${width || 226}px`};
  padding: 8px 8px 0 8px;

  .input-base-wrapper {
    background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  }

  &:hover {
    border-color: ${({ theme }) => theme.palette.custom['gray-60']};
  }

  ul {
    height: 170px;
  }
  ul > li > .ellipsis-with-tooltip {
    max-width: 80%;
  }
`;

export const MultiSelectFooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 12px 4px;
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};

  button:first-of-type {
    margin-right: 5px;
  }
`;

export const TagMultiSelectContentStyled = styled.div`
  p:first-of-type {
    padding: 12px 12px 8px 8px;
  }

  ul {
    overflow: auto;
  }
`;
