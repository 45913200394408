import React, { useCallback, useMemo } from 'react';
import {
  Button,
  DialogModalHeader,
  Tooltip,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import {
  DialogModalContentStyled,
  DialogModalFooterStyled,
  DialogModalStyled,
} from './CatalogExplorerDisplayPreferences.styles';
import { FieldType } from 'src/services';
import { DataFieldsByType, DisplayFieldsByType } from '../types';
import { useManageDisplayedFields } from './useManageDisplayedFields';
import { AITagsFieldsSection } from './DataFieldsByTypeSections/AITagsSection/AITagsFieldsSection';
import { CatalogExplorerDisplayPreferencesActions } from './CatalogExplorerDisplayPreferences.actions';
import { Dispatch } from 'src/components-bl/types';
import { CatalogFieldsSection } from './DataFieldsByTypeSections/CatalogSection/CatalogFieldsSection';

interface DisplayPreferencesProps {
  shopId: number;
  catalogName: string;
  locale: string;
  dataFieldsByType: DataFieldsByType;
  displayFieldsPreferencesByType: DisplayFieldsByType;
  isMerchRulesAITagsEnabled: boolean;
  onClose: () => void;
  dispatch: Dispatch;
}

export const CatalogExplorerDisplayPreferences = ({
  shopId,
  catalogName,
  locale,
  dataFieldsByType,
  displayFieldsPreferencesByType,
  isMerchRulesAITagsEnabled,
  onClose,
  dispatch,
}: DisplayPreferencesProps): JSX.Element => {
  /**
   * Catalog Type
   */
  const catalogAPIDataFields = useMemo(
    () => dataFieldsByType[FieldType.CatalogField] || [],
    [dataFieldsByType.catalog_field]
  );

  const categoryManagedForm = useManageDisplayedFields({
    fieldType: FieldType.CatalogField,
    dataFieldsAPI: catalogAPIDataFields,
    displayFieldsPreferences: displayFieldsPreferencesByType.catalog_field,
    locale,
  });

  /**
   * AI Tags Type
   */
  const aiTagsAPIDataFields = useMemo(
    () => (isMerchRulesAITagsEnabled ? dataFieldsByType[FieldType.AiTag] || [] : []),
    [isMerchRulesAITagsEnabled, dataFieldsByType.ai_tag]
  );

  const aiDisplayFieldsPreferences = useMemo(() => {
    return isMerchRulesAITagsEnabled ? displayFieldsPreferencesByType.ai_tag : [];
  }, [isMerchRulesAITagsEnabled, displayFieldsPreferencesByType.ai_tag]);

  const aiTagsManagedForm = useManageDisplayedFields({
    fieldType: FieldType.AiTag,
    dataFieldsAPI: aiTagsAPIDataFields,
    displayFieldsPreferences: aiDisplayFieldsPreferences,
    locale,
  });

  /**
   * All
   */
  const onSaveAllChanges = useCallback(() => {
    const displayPreferencesData = {
      [FieldType.CatalogField]: categoryManagedForm.displayedFormList,
      [FieldType.AiTag]: aiTagsManagedForm.displayedFormList,
    };

    dispatch(
      CatalogExplorerDisplayPreferencesActions.setDisplayPreferences({
        shopId,
        catalogName,
        data: displayPreferencesData,
      })
    );

    onClose();
  }, [categoryManagedForm]);

  const totalDisplayedItems =
    categoryManagedForm.displayedFormList.length + aiTagsManagedForm.displayedFormList.length;

  const shouldEnableApply =
    totalDisplayedItems > 0 && (categoryManagedForm.isDirty || aiTagsManagedForm.isDirty);

  return (
    <DialogModalStyled open onClose={onClose}>
      <>
        <DialogModalHeader>
          <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
            Configure Display Preferences
          </Typography>
        </DialogModalHeader>
        <DialogModalContentStyled>
          <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
            Select the data fields to display in your catalog preview.
          </Typography>
          <CatalogFieldsSection
            displayedFormList={categoryManagedForm.displayedFormList}
            unusedDataFieldsOptions={categoryManagedForm.unselectedDataFieldOptions}
            locale={locale}
            onItemValueChange={categoryManagedForm.onItemValueChange}
            onItemRemove={categoryManagedForm.onItemRemove}
            onListOrderChange={categoryManagedForm.onListOrderChange}
            onAddNewItems={categoryManagedForm.onAddNewItems}
          />
          <AITagsFieldsSection
            displayedFormList={aiTagsManagedForm.displayedFormList}
            unusedDataFieldsOptions={aiTagsManagedForm.unselectedDataFieldOptions}
            locale={locale}
            onItemValueChange={aiTagsManagedForm.onItemValueChange}
            onItemRemove={aiTagsManagedForm.onItemRemove}
            onListOrderChange={aiTagsManagedForm.onListOrderChange}
            onAddNewItems={aiTagsManagedForm.onAddNewItems}
            isMerchRulesAITagsEnabled={isMerchRulesAITagsEnabled}
            hasAIDataFields={!!dataFieldsByType.ai_tag?.length}
          />
        </DialogModalContentStyled>
        <DialogModalFooterStyled>
          <Tooltip
            value='At least 1 data field needs to be selected'
            position='top center'
            disabled={totalDisplayedItems > 0}
          >
            <Button variant='primary' disabled={!shouldEnableApply} onClick={onSaveAllChanges}>
              Save changes
            </Button>
          </Tooltip>
          <Button variant='tertiary' onClick={onClose}>
            Cancel
          </Button>
        </DialogModalFooterStyled>
      </>
    </DialogModalStyled>
  );
};
