import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isAcceptedDataField } from 'src/components-bl/CatalogManager/CatalogExplorer/CatalogExplorer.helpers';
import { createInitialPreferences } from 'src/components-bl/CatalogManager/CatalogExplorer/CatalogExplorerDisplayPreferences/CatalogExplorerDisplayPreferences.helpers';
import { DisplayedFieldItem } from 'src/components-bl/CatalogManager/CatalogExplorer/CatalogExplorerDisplayPreferences/types';
import {
  DisplayFieldsByType,
  DisplayPreferencesState,
} from 'src/components-bl/CatalogManager/CatalogExplorer/types';
import { usePreviousState } from 'src/hooks';
import { FieldType, ShopDataField } from 'src/services';

const STATUS_ENUM = {
  INIT: 'INIT',
  CHANGE: 'CHANGE',
};

interface UseCatalogExplorerProps {
  shopId: number;
  catalogName: string;
  displayPrefStoreState?: DisplayPreferencesState[number][string];
  catalogTypeDataFields: ShopDataField[];
  aiTagsTypeDataFields: ShopDataField[];
  isMerchRulesAITagsEnabled: boolean;
  isWaitingForDataFields: boolean;
}

interface UseCatalogExplorerReturn {
  displayFieldsByType: DisplayFieldsByType;
}

const initialState = {
  [FieldType.CatalogField]: [],
  [FieldType.AiTag]: [],
};

export const useStoredCatalogDisplayPref = ({
  shopId,
  catalogName,
  displayPrefStoreState,
  catalogTypeDataFields,
  aiTagsTypeDataFields,
  isMerchRulesAITagsEnabled,
  isWaitingForDataFields,
}: UseCatalogExplorerProps): UseCatalogExplorerReturn => {
  const [displayFieldsState, setUpdatedDisplayFieldsState] =
    useState<DisplayFieldsByType>(initialState);

  const prevDisplayPrefStoreState = usePreviousState(displayPrefStoreState);

  /**
   *  Filter supported data fields & create Map
   * */
  const catalogDataFieldsMap: Record<string, ShopDataField> = useMemo(() => {
    return (catalogTypeDataFields || []).reduce((prev, next: ShopDataField) => {
      const isAccepted = isAcceptedDataField({
        dataField: next,
        catalogName,
        hasAITagsPemission: isMerchRulesAITagsEnabled,
      });

      if (!isAccepted) {
        return prev;
      }

      prev[next.name] = next;
      return prev;
    }, {});
  }, [catalogTypeDataFields]);

  const aiTagsDataFieldsMap: Record<string, ShopDataField> = useMemo(() => {
    return (aiTagsTypeDataFields || []).reduce((prev, next: ShopDataField) => {
      const isAccepted = isAcceptedDataField({
        dataField: next,
        catalogName,
        hasAITagsPemission: isMerchRulesAITagsEnabled,
      });

      if (!isAccepted) {
        return prev;
      }

      prev[next.name] = next;
      return prev;
    }, {});
  }, [aiTagsTypeDataFields]);

  const getStoredPreferencesOrInit = useCallback(() => {
    if (displayPrefStoreState?.preferences) {
      return displayPrefStoreState.preferences;
    }

    return createInitialPreferences({ catalogDataFieldsMap });
  }, [displayPrefStoreState?.preferences, catalogDataFieldsMap]);

  /**
   * Check display items list state against fresh dataFieldsMap:
   * - Filter out those items which are not exist in dataFieldsMap.
   * - Update text in display item incase it has (displayName) changed in dataFieldsMap.
   */
  const cleanAndUpdateList = useCallback(
    ({
      displayPreferencesList,
      dataFieldsMap,
    }: {
      displayPreferencesList: DisplayedFieldItem[];
      dataFieldsMap: Record<string, ShopDataField>;
    }) => {
      return displayPreferencesList.reduce((prev, item) => {
        const isDataFieldExist = dataFieldsMap[item.value];

        if (!isDataFieldExist) {
          return prev;
        }

        const hasDisplayNameChanged = item.text !== dataFieldsMap[item.value].displayName;

        if (!hasDisplayNameChanged) {
          prev.push(item);
          return prev;
        }

        const updatedItem = { ...item, text: dataFieldsMap[item.value].displayName };

        return [...prev, updatedItem];
      }, []);
    },
    []
  );

  const updateState = useCallback(
    ({
      prevState,
      updateType,
    }: {
      prevState: DisplayFieldsByType;
      updateType: typeof STATUS_ENUM.INIT | typeof STATUS_ENUM.CHANGE;
    }): DisplayFieldsByType => {
      const storedRefOrInit = getStoredPreferencesOrInit();

      const getCatalogFieldsList = () => {
        let catalogDisplayPreferenceList;

        switch (updateType) {
          case STATUS_ENUM.INIT: {
            catalogDisplayPreferenceList = storedRefOrInit.catalog_field;
            break;
          }
          case STATUS_ENUM.CHANGE: {
            catalogDisplayPreferenceList = displayPrefStoreState?.preferences.catalog_field;
            break;
          }
          default:
            catalogDisplayPreferenceList = prevState?.catalog_field;
        }

        return cleanAndUpdateList({
          displayPreferencesList: catalogDisplayPreferenceList || [],
          dataFieldsMap: catalogDataFieldsMap,
        });
      };

      const getAITagsTypeList = () => {
        if (!isMerchRulesAITagsEnabled) {
          return [];
        }

        let aiTagsDisplayPreferenceList;

        switch (updateType) {
          case STATUS_ENUM.INIT: {
            aiTagsDisplayPreferenceList = storedRefOrInit.ai_tag;
            break;
          }
          case STATUS_ENUM.CHANGE: {
            aiTagsDisplayPreferenceList = displayPrefStoreState?.preferences?.ai_tag;
            break;
          }
          default:
            aiTagsDisplayPreferenceList = prevState?.ai_tag;
        }

        return cleanAndUpdateList({
          displayPreferencesList: aiTagsDisplayPreferenceList || [],
          dataFieldsMap: aiTagsDataFieldsMap,
        });
      };

      const updatedCatalogFieldsList = getCatalogFieldsList();
      const updatedAIFieldsList = getAITagsTypeList();

      return {
        ...prevState,
        [FieldType.CatalogField]: updatedCatalogFieldsList,
        [FieldType.AiTag]: updatedAIFieldsList,
      };
    },
    [
      shopId,
      catalogName,
      cleanAndUpdateList,
      displayPrefStoreState?.preferences,
      catalogDataFieldsMap,
      aiTagsDataFieldsMap,
      isMerchRulesAITagsEnabled,
      isWaitingForDataFields,
    ]
  );

  /**
   * Update list:
   *  1. Display names
   *  2. Remove not existed data fields from display preferences items
   */
  useEffect(() => {
    if (isWaitingForDataFields) {
      return;
    }

    setUpdatedDisplayFieldsState(prevState => {
      const isInit = prevState.catalog_field?.length === 0;
      let statusType;

      if (isInit) {
        statusType = STATUS_ENUM.INIT;
      } else if (
        !isEqual(prevDisplayPrefStoreState?.preferences, displayPrefStoreState?.preferences)
      ) {
        statusType = STATUS_ENUM.CHANGE;
      }

      return updateState({
        updateType: statusType,
        prevState,
      });
    });
  }, [
    shopId,
    catalogName,
    catalogDataFieldsMap,
    aiTagsDataFieldsMap,
    displayPrefStoreState?.preferences,
    prevDisplayPrefStoreState?.preferences,
    isMerchRulesAITagsEnabled,
    isWaitingForDataFields,
  ]);

  return {
    displayFieldsByType: displayFieldsState,
  };
};
