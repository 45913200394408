import React from 'react';
import { CustomInspirationsGalleryLayoutType } from 'src/services';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { CustomInspirationGalleryDraft } from '../../../../../../types';
import { SliderRow } from '../SliderRow/SliderRow';
import { ConfigurationRowStyled } from './LayoutSpecificConfigurations.styles';

interface LayoutSpecificConfigurationsProps {
  layout: CustomInspirationGalleryDraft['layout'];
  onChange: (partial: Partial<CustomInspirationGalleryDraft['layout']>) => void;
}

export const LayoutSpecificConfigurations = ({
  layout,
  onChange,
}: LayoutSpecificConfigurationsProps): JSX.Element => {
  if (layout.type === CustomInspirationsGalleryLayoutType.Grid) {
    return (
      <>
        <ConfigurationRowStyled>
          <SliderRow
            key='gridImagesPerRow'
            value={layout.gridImagesPerRow}
            onChange={value => onChange({ gridImagesPerRow: value })}
            min={3}
            max={5}
          />
          <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
            Number of images per row
          </Typography>
        </ConfigurationRowStyled>

        <ConfigurationRowStyled>
          <SliderRow
            key='gridNumberOfRows'
            value={layout.gridNumberOfRows}
            onChange={value => onChange({ gridNumberOfRows: value })}
            min={2}
            max={4}
          />
          <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
            Number of rows
          </Typography>
        </ConfigurationRowStyled>
      </>
    );
  }

  return (
    <ConfigurationRowStyled>
      <SliderRow
        key='carouselNumberOfImages'
        value={layout.carouselNumberOfImages}
        onChange={value => onChange({ carouselNumberOfImages: value })}
        min={1}
        max={5}
      />
      <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
        Number of images per slide
      </Typography>
    </ConfigurationRowStyled>
  );
};
