import React from 'react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import {
  SectionContentStyled,
  SectionContainerStyled,
  SectionHeaderStyled,
} from '../DataFieldsByTypesSections.styles';
import { DataFieldsSectionProps } from '../DataFieldsByTypeSections.types';
import { DataFieldsSelectHeader } from '../../DataFieldsSelectHeader';
import { DisplayedFieldsItems } from '../../DisplayedFieldsItems/DisplayedFieldsItems';

type CatalogFieldsSectionProps = DataFieldsSectionProps;

export const CatalogFieldsSection = React.memo(
  ({
    displayedFormList: dataFieldsFormList,
    unusedDataFieldsOptions,
    locale,
    onItemValueChange,
    onItemRemove,
    onListOrderChange,
    onAddNewItems,
  }: CatalogFieldsSectionProps) => {
    return (
      <SectionContainerStyled>
        <SectionHeaderStyled>
          <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
            Fields to display
          </Typography>
        </SectionHeaderStyled>
        <SectionContentStyled>
          <DataFieldsSelectHeader
            unusedDataFieldsOptions={unusedDataFieldsOptions}
            locale={locale}
            onChange={onAddNewItems}
          />
          <DisplayedFieldsItems
            dataFieldsFormList={dataFieldsFormList}
            unusedDataFieldsOptions={unusedDataFieldsOptions}
            onItemRemove={onItemRemove}
            onItemValueChange={onItemValueChange}
            onListOrderChange={onListOrderChange}
            onAddNewItems={onAddNewItems}
          ></DisplayedFieldsItems>
        </SectionContentStyled>
      </SectionContainerStyled>
    );
  }
);
