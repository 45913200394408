export const filterListIfChanged = ({
  currentList,
  displayFieldsMap,
}: {
  currentList: string[];
  displayFieldsMap;
}) => {
  const hasIrrelevantFilter = currentList.some(filterName => !displayFieldsMap[filterName]);

  if (!hasIrrelevantFilter) return currentList;

  return currentList.filter(filterName => !!displayFieldsMap[filterName]);
};
