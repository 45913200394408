import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  TextBox,
  ConfirmationDialog,
  SelectType,
  SelectOnChangeEvent,
  MenuItem,
  Select,
} from 'src/components-dummy';
import { useValidateSchema } from 'src/hooks';
import { shopCreationFormActions } from './Actions/ShopCreationFormActions';
import { formValidationSchema, initialFormState, shopTypeDropdownOptions } from './constants';
import { ContentStyled, FooterStyled, ShopCreationFormStyled } from './ShopCreationForm.styles';
import { ShopCreationFormProps, ShopCreationFormState } from './types';
import { isEqual } from 'lodash';
import { ShopTypes } from 'src/services';
import { SalesforceAccountAutocomplete } from './components/SalesforceAccountAutocomplete/SalesforceAccountAutocomplete';

export const ShopCreationForm = ({
  accountId,
  dispatch,
  shopsListPath,
}: ShopCreationFormProps): JSX.Element => {
  const [formState, setFormState] = useState(initialFormState);
  const [isInProgress, setIsInProgress] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const updateIsDirty = useCallback(
    (dirty: boolean) => {
      setIsDirty(dirty);
      dispatch(shopCreationFormActions.notifyIsDirty({ isDirty: dirty }));
    },
    [setIsDirty, dispatch]
  );

  const shouldDisableSalesforceInput = useMemo(
    () => formState.shopType !== ShopTypes.ShopType.Production,
    [formState.shopType]
  );

  const { errors, isValid, resetValidationState, validate } =
    useValidateSchema<ShopCreationFormState>({
      schema: formValidationSchema,
    });

  const onStateChange = useCallback(
    (partialState: Partial<ShopCreationFormState>) => {
      const newState: ShopCreationFormState = { ...formState, ...partialState };

      const isShopTypeChange = formState.shopType !== newState.shopType;

      if (isShopTypeChange) {
        newState.salesforceAccount = initialFormState.salesforceAccount;
      }

      setFormState(newState);

      validate({ dataToValidate: newState });

      const newIsDirty = !isEqual(formState, initialFormState);
      updateIsDirty(newIsDirty);
    },
    [setFormState, formState, validate, updateIsDirty]
  );

  const onShopTypeChange: SelectOnChangeEvent = useCallback(
    event => {
      const shopType = event.target.value as ShopTypes.ShopType;
      onStateChange({ shopType });
    },
    [onStateChange]
  );

  const navigateToShop = useCallback(
    (shopId: number) => {
      dispatch(
        shopCreationFormActions.navigateToShop({
          shopId,
        })
      );
    },
    [dispatch]
  );

  const reset = useCallback(() => {
    updateIsDirty(false);
    setFormState(initialFormState);
    resetValidationState();
  }, [setFormState, resetValidationState, updateIsDirty]);

  const onSubmit = useCallback(async () => {
    if (!isValid) return;

    setIsInProgress(true);

    try {
      const createdShop = await (
        dispatch(
          shopCreationFormActions.createShop({
            salesforceAccountId: formState.salesforceAccount?.salesforceAccountId,
            shopName: formState.shopName,
            shopType: formState.shopType,
            accountId,
          })
        ) as any
      ).unwrap();

      updateIsDirty(false);

      navigateToShop(createdShop.shopId);
    } catch (error) {
      console.error(error);
    }

    setIsInProgress(false);
  }, [isValid, dispatch, formState, setIsInProgress, navigateToShop]);

  const onCancelModal = useCallback(() => {
    dispatch(
      shopCreationFormActions.navigateTo({
        navigateTo: shopsListPath,
      })
    );
  }, [shopsListPath]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <ShopCreationFormStyled onCancel={onCancelModal}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Create new shop</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ContentStyled>
        <TextBox
          label='Shop Name'
          placeholder='Write here'
          value={formState.shopName}
          error={errors.shopName?.message}
          onChange={shopName => onStateChange({ shopName })}
        />
        <Select
          type={SelectType.Primary}
          label='Shop type'
          placeholder='Select shop type'
          value={formState.shopType}
          onChange={onShopTypeChange}
          isError={Boolean(errors.shopType)}
          errorMessage={errors.shopType?.message}
        >
          {shopTypeDropdownOptions.map(({ text, value }) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
        </Select>
        <SalesforceAccountAutocomplete
          disabled={shouldDisableSalesforceInput}
          selectedAccount={formState.salesforceAccount}
          error={errors.salesforceAccount?.message}
          onSelectAccount={salesforceAccount => onStateChange({ salesforceAccount })}
        />
      </ContentStyled>
      <FooterStyled>
        <Button
          variant='primary'
          onClick={onSubmit}
          disabled={!(isDirty && isValid)}
          loading={isInProgress}
        >
          Create
        </Button>

        <Button variant='tertiary' onClick={onCancelModal}>
          Cancel
        </Button>
      </FooterStyled>
    </ShopCreationFormStyled>
  );
};
