import React from 'react';
import { SectionWithTitle } from '../SectionWithTitle/SectionWithTitle';

export const LinkedinSection = (): JSX.Element => {
  return (
    <SectionWithTitle title='Syte on LinkedIn'>
      <iframe
        src='https://www.juicer.io/api/feeds/syte-ai-bdf4106c-17a8-4443-9931-ca5eab27164f/iframe?per=8'
        frameBorder='0'
        width='100%'
        height='1000'
      ></iframe>
    </SectionWithTitle>
  );
};
