import React, { useMemo } from 'react';
import { HomeContent, HomeContentProps } from 'src/components-bl/Home/Home';
import { Page, TypographyVariant } from 'src/components-dummy';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { User } from 'src/services/src/service/types/users';

export type HomeContainerProps = HomeContentProps;

export function getUserDisplayName({ firstName }: Pick<User, 'firstName'>): string | undefined {
  if (firstName.length > 0) {
    return firstName;
  }

  return undefined;
}

export const HomeContainer = (props: HomeContainerProps): JSX.Element => {
  const user = useAppSelector(state => state.global.loggedInUser);
  const dispatch = useAppDispatch();

  const welcomeText = useMemo(() => {
    const userName = getUserDisplayName(user);

    if (!userName) {
      return 'Welcome';
    }

    return `Welcome ${userName}`;
  }, [user]);

  return (
    <Page layout='default' className='home-page'>
      <Page.Header>
        <Page.Title variant={TypographyVariant.SmallBold}>{welcomeText}</Page.Title>
      </Page.Header>
      <Page.Content>
        <HomeContent {...props} dispatch={dispatch} lastVisitedURL={user?.lastVisited?.uri} />
      </Page.Content>
    </Page>
  );
};
