import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  AutoCompleteOption,
  EllipsisWithTooltip,
  TypographyType,
  TypographyVariant,
  useDebounce,
} from 'src/components-dummy';
import { Dispatch } from 'src/components-bl';
import { useIsInViewPort } from 'src/hooks';
import { dataFieldAutoSuggestionActions } from './DataFieldAutoSuggestion.actions';
import {
  CardStyled,
  DataFieldAutoSuggestionStyled,
  NoResultsTypographyStyled,
  RowStyled,
  SearchInputStyled,
} from './DataFieldAutoSuggestion.styles';
import { imageTagToDisplayValue } from '../utils';

type DataFieldValue = { title: string; sku: string };

interface DataFieldAutoSuggestionProps {
  onChange: (title: string, sku: string) => void;
  dispatch: Dispatch;
  shopId: number;
}

const PAGE_SIZE = 50;

export const DataFieldAutoSuggestion = ({
  onChange,
  dispatch,
  shopId,
}: DataFieldAutoSuggestionProps): JSX.Element => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { isInView, setRef } = useIsInViewPort({});

  const [options, setOptions] = useState<AutoCompleteOption<DataFieldValue>[]>([]);
  const [textBoxValue, setTextBoxValue] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [currentAfter, setCurrentAfter] = useState<Record<string, string> | undefined>(undefined);
  const [hasReachedEnd, setHasReachEnd] = useState(false);

  const fetchDataFieldOptionsDebounced = useDebounce(
    ({
      searchTerm,
      after,
      previousOptions,
      shouldLoadMore,
    }: {
      searchTerm: string;
      after?: Record<string, string>;
      previousOptions: AutoCompleteOption<DataFieldValue>[];
      shouldLoadMore: boolean;
    }) => {
      if (searchTerm.length < 2 || !shouldLoadMore) {
        setOptions([]);
        return;
      }

      setIsLoading(true);

      (
        dispatch(
          dataFieldAutoSuggestionActions.getValues({
            shopId,
            primaryDataField: 'title',
            additionalDataFields: ['sku', 'parent_sku', 'color_sku', 'size_sku'],
            searchTerm,
            limit: PAGE_SIZE,
            after,
          })
        ) as any
      )
        .unwrap()
        .then(
          ({
            values: dataFieldOptions,
            after: newAfter,
          }: {
            values: DataFieldValue[];
            after?: Record<string, string>;
          }) => {
            setOptions([
              ...previousOptions,
              ...dataFieldOptions.map(option => {
                return {
                  value: option,
                  title: option.title,
                };
              }),
            ]);
            setIsLoading(false);

            setCurrentAfter(newAfter);
            setHasReachEnd(dataFieldOptions.length === 0);
          }
        )
        .catch((apiError: Error) => {
          console.error(apiError);
          setOptions([]);
          setIsLoading(false);
        });
    },
    500
  );

  const onSelectValue = useCallback(
    ({ title, sku }: DataFieldValue) => {
      onChange(title, sku);
    },
    [onChange]
  );

  const onTextBoxChange = (input: string): void => {
    const searchValueTrimmed = input.trim();
    setTextBoxValue(input);
    setCurrentAfter(undefined);
    setOptions([]);
    setHasReachEnd(false);

    fetchDataFieldOptionsDebounced({
      searchTerm: searchValueTrimmed,
      after: undefined,
      previousOptions: [],
      shouldLoadMore: true,
    });
  };

  useEffect(() => {
    (searchInputRef.current?.firstElementChild as any)?.focus();
  }, [searchInputRef.current]);

  useEffect(() => {
    if (isInView) {
      fetchDataFieldOptionsDebounced({
        searchTerm: textBoxValue,
        after: currentAfter,
        previousOptions: options,
        shouldLoadMore: !hasReachedEnd,
      });
    }
  }, [isInView]);

  return (
    <DataFieldAutoSuggestionStyled>
      <SearchInputStyled
        value={textBoxValue}
        onChange={onTextBoxChange}
        placeholder='Search by Product Title, or Product SKU'
        ref={searchInputRef}
      />
      {!!(textBoxValue.length > 0) && (
        <CardStyled>
          {options.length > 0 ? (
            options.map(option => {
              const displayValue = imageTagToDisplayValue(option.value);
              return (
                <RowStyled
                  key={option.value.sku}
                  onClick={() => onSelectValue(option.value)}
                  isClickable
                  ref={setRef}
                >
                  <EllipsisWithTooltip tooltipText={displayValue}>
                    {displayValue}
                  </EllipsisWithTooltip>
                </RowStyled>
              );
            })
          ) : (
            <RowStyled isClickable={false}>
              <NoResultsTypographyStyled
                type={TypographyType.Body}
                variant={TypographyVariant.MediumMedium}
              >
                {loading ? 'Loading...' : 'No results'}
              </NoResultsTypographyStyled>
            </RowStyled>
          )}
        </CardStyled>
      )}
    </DataFieldAutoSuggestionStyled>
  );
};
