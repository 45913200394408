export enum UniqueByField {
  ParentSku = 'parent_sku',
  Sku = 'sku',
}

export const MANDATORY_DATA_FIELDS_MAP: Record<string, { value: string; text: string }> = {
  imageUrl: { value: 'imageUrl', text: 'Image' },
  title: { value: 'title', text: 'Title' },
  parent_sku: { value: 'parent_sku', text: 'Parent SKU' },
  color_sku: { value: 'color_sku', text: 'Color SKU' },
  sku: { value: 'sku', text: 'SKU' },
  price: { value: 'price', text: 'Price' },
  originalPrice: { value: 'originalPrice', text: 'Original Price' },
  categories: { value: 'categories', text: 'Categories' },
  brand: { value: 'brand', text: 'Brand' },
};

export const MANDATORY_CATALOG_ORDERED_DATA_FIELDS: string[] = [
  MANDATORY_DATA_FIELDS_MAP.imageUrl.value,
  MANDATORY_DATA_FIELDS_MAP.title.value,
  MANDATORY_DATA_FIELDS_MAP.parent_sku.value,
  MANDATORY_DATA_FIELDS_MAP.color_sku.value,
  MANDATORY_DATA_FIELDS_MAP.sku.value,
  MANDATORY_DATA_FIELDS_MAP.price.value,
  MANDATORY_DATA_FIELDS_MAP.originalPrice.value,
  MANDATORY_DATA_FIELDS_MAP.categories.value,
  MANDATORY_DATA_FIELDS_MAP.brand.value,
];

export const MANDATORY_ORDERED_TABLE_COLUMN_VALUES = [
  'index',
  ...MANDATORY_CATALOG_ORDERED_DATA_FIELDS,
];
