import React from 'react';
import {
  Typography,
  TypographyType,
  TypographyVariant,
  AvailableIcons,
  Icon,
} from 'src/components-dummy';
import {
  AITagsTryItNowPromo,
  SectionContainerStyled,
  SectionHeaderStyled,
  AITagsDisabledTitleStyled,
  AITagsLearnMoreItemStyled,
} from '../DataFieldsByTypesSections.styles';

export const NoAITagsLearnMoreInfo = () => {
  const onClick = () => {
    window.open('https://support.syte.ai/space/ET/3482190002/Deep+Tags+Overview', '_blank');
  };

  return (
    <SectionContainerStyled>
      <SectionHeaderStyled>
        <AITagsDisabledTitleStyled>
          <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
            AI Tags
          </Typography>
        </AITagsDisabledTitleStyled>
        <AITagsTryItNowPromo>
          <Icon name={AvailableIcons.AITagV2} />{' '}
          <Typography type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
            NEW! &nbsp;
          </Typography>
          <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
            Contact your CSM to learn more
          </Typography>
        </AITagsTryItNowPromo>
      </SectionHeaderStyled>
      <AITagsLearnMoreItemStyled onClick={onClick}>
        <div>
          <Icon name={AvailableIcons.InformationV2} />
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            Unveil to learn more
          </Typography>
        </div>
        <Icon name={AvailableIcons.ArrowRec} />
      </AITagsLearnMoreItemStyled>
    </SectionContainerStyled>
  );
};
