import React, { useCallback } from 'react';
import { DisplayedFieldItem, UnselectedDataFieldOption } from '../types';
import { AvailableIcons, DraggableList, ReOrderedList, Tooltip } from 'src/components-dummy';
import {
  DisplayedFieldsItemsStyled,
  DisplayedItemRowStyled,
  DisplayedItemStyled,
  CloseIconStyled,
  DragIconStyled,
  IconWrapperStyled,
  DraggableListWrapperStyled,
} from './DisplayedFieldsItems.styles';
import { SearchableDropdownWithTrigger } from '../SearchableDropdownWithTrigger/SearchableDropdownWithTrigger';
import { NO_ADDITIONAL_FIELDS_TEXT } from '../CatalogExplorerDisplayPreferences.constants';
import { getItemDisplayText } from '../../CatalogExplorer.helpers';

interface DisplayedFieldsItemsProps {
  dataFieldsFormList: DisplayedFieldItem[];
  unusedDataFieldsOptions: UnselectedDataFieldOption[];
  onItemRemove: (id: string) => void;
  onItemValueChange: ({
    id,
    option,
  }: {
    id: string;
    option: UnselectedDataFieldOption['value'];
  }) => void;
  onListOrderChange: (newList: ReOrderedList) => void;
  onAddNewItems: (selectedItemValues: string[]) => void;
}

export const DisplayedFieldsItems = React.memo(
  ({
    dataFieldsFormList,
    unusedDataFieldsOptions,
    onItemValueChange,
    onListOrderChange,
    onItemRemove,
    onAddNewItems,
  }: DisplayedFieldsItemsProps): JSX.Element => {
    const handleSelectChange = useCallback(
      itemId => (dataFieldValue: string) => {
        onItemValueChange({ id: itemId, option: dataFieldValue });
      },
      [onItemValueChange]
    );

    const handleItemRemove = useCallback(
      itemId => () => {
        onItemRemove(itemId);
      },
      [onItemRemove]
    );

    const handleAddNewItem = useCallback(
      () => (dataFieldValue: string) => {
        onAddNewItems([dataFieldValue]);
      },
      [onAddNewItems]
    );

    return (
      <DisplayedFieldsItemsStyled>
        {dataFieldsFormList.length ? (
          <DraggableListWrapperStyled>
            <DraggableList onChange={onListOrderChange} isDragDisabled={false}>
              {dataFieldsFormList.map(item => {
                const { fieldType, value, text, id } = item;

                const selectOptionsList = [
                  {
                    value: value,
                    text: getItemDisplayText({ value, text, fieldType }),
                  },
                  ...unusedDataFieldsOptions,
                ];

                return (
                  <DisplayedItemStyled key={item.value}>
                    <DisplayedItemRowStyled>
                      <IconWrapperStyled>
                        <DragIconStyled name={AvailableIcons.DragHandleVerticalDouble} />
                      </IconWrapperStyled>
                      <SearchableDropdownWithTrigger
                        options={selectOptionsList}
                        value={value}
                        key={id}
                        onChange={handleSelectChange(id)}
                      />
                      <IconWrapperStyled onClick={handleItemRemove(id)} isPointerCursor>
                        <CloseIconStyled name={AvailableIcons.CloseRoundedSmall} />
                      </IconWrapperStyled>
                    </DisplayedItemRowStyled>
                  </DisplayedItemStyled>
                );
              })}
            </DraggableList>
          </DraggableListWrapperStyled>
        ) : null}

        {/* Add New Item */}
        <DisplayedItemRowStyled isDisabled={!unusedDataFieldsOptions.length}>
          <IconWrapperStyled isDisable>
            <DragIconStyled name={AvailableIcons.DragHandleVerticalDouble} />
          </IconWrapperStyled>
          <Tooltip
            value={NO_ADDITIONAL_FIELDS_TEXT}
            position='top center'
            disabled={!!unusedDataFieldsOptions.length}
          >
            <div style={{ pointerEvents: !unusedDataFieldsOptions.length ? 'none' : 'initial' }}>
              <SearchableDropdownWithTrigger
                options={unusedDataFieldsOptions}
                value={''}
                key='NEW_ITEM'
                placeholder='Select Fields...'
                onChange={handleAddNewItem()}
              />
            </div>
          </Tooltip>
          <IconWrapperStyled isHidden={true}>
            <CloseIconStyled name={AvailableIcons.CloseRoundedSmall} />
          </IconWrapperStyled>
        </DisplayedItemRowStyled>
      </DisplayedFieldsItemsStyled>
    );
  }
);
