import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  collectionsService,
  ExportCollectionTitlesCSVArguments,
  ImportCollectionTitlesCSVArguments,
} from 'src/services';

export const collectionsTranslationsActions = {
  export: createAsyncThunk(
    'CollectionsTranslations/export',
    async ({ shopId, variantId }: ExportCollectionTitlesCSVArguments, { rejectWithValue }) => {
      try {
        return await collectionsService.exportCollectionTitlesCSV({
          shopId,
          variantId,
        });
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  import: createAsyncThunk(
    'CollectionsTranslations/import',
    async (
      {
        file,
        shopId,
        onUploadProgress,
        cancellationSignal,
        variantId,
      }: ImportCollectionTitlesCSVArguments,
      { rejectWithValue }
    ) => {
      try {
        return await collectionsService.importCollectionTitlesCSV({
          file,
          shopId,
          onUploadProgress,
          cancellationSignal,
          variantId,
        });
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
