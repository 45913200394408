import React from 'react';

import { useAppSelector } from '../../../../hooks';

import { Collection, CollectionsList, Dispatch, EmptyCollections } from '../../../../components-bl';
import { SearchInput } from '../../../../components-dummy';
import { useSortedAndFilteredCollections } from '../../../../components-bl/hooks';

import './CollectionsMain.scss';

interface CollectionsMainProps {
  shopId: number;
  dispatch: Dispatch;
  collections: Collection[];
  isListEmpty: boolean;
  onCreateDefaultCollection?: VoidFunction;
  onEditCollection?: (collectionId: string) => void;
  variantId?: string;
}

export const CollectionsMain = ({
  shopId,
  dispatch,
  collections,
  isListEmpty,
  onCreateDefaultCollection,
  onEditCollection,
  variantId,
}: CollectionsMainProps) => {
  const { sortingValue } = useAppSelector(state => state.collections);

  const { filteredItems, searchInputValue, onSearchInputChange } = useSortedAndFilteredCollections({
    sortingValue,
    collections,
  });

  return (
    <main className='collections-main'>
      {isListEmpty ? (
        <EmptyCollections onCreateDefaultCollection={onCreateDefaultCollection} />
      ) : (
        <>
          <div className='collections-sorting-and-filtering'>
            <div className='collections-search'>
              <SearchInput
                value={searchInputValue}
                onChange={onSearchInputChange}
                placeholder='Search...'
              />
            </div>
          </div>
          <CollectionsList
            shopId={shopId}
            collectionsList={filteredItems}
            dispatch={dispatch}
            onEditCollection={onEditCollection}
            variantId={variantId}
          />
        </>
      )}
    </main>
  );
};
