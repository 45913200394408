import { AxiosResponse } from 'axios';
import { Buffer } from 'buffer';
import { UserTypes } from '../types';
import {
  CreateUserArgs,
  CreateUserPayload,
  ForgotPasswordPayload,
  UpdateUserArgs,
  UpdateUserPayload,
} from './types';

export class UsersMapper {
  static mapBackendUser({
    userId,
    email,
    online,
    firstName,
    lastName,
    accessLevel: role,
    accounts,
    lastVisited,
  }: UserTypes.UserBackEnd): UserTypes.User {
    return {
      userId,
      email,
      online,
      firstName,
      lastName,
      role,
      accountId: accounts?.[0] || 0,
      lastVisited,
    };
  }

  static map({ data }: AxiosResponse): UserTypes.User {
    const backendUser: UserTypes.UserBackEnd = data.user || data.data.user;

    return this.mapBackendUser(backendUser);
  }

  static mapSuccessMessage({ data }: AxiosResponse): string {
    return data.data.message;
  }

  static getCreateUserPayload({ userData, accountId }: CreateUserArgs): CreateUserPayload {
    return { user: userData, accountId };
  }

  static getUpdateUserPayload({ userData, accountId }: UpdateUserArgs): UpdateUserPayload {
    const updateObject: UpdateUserArgs['userData'] = {
      ...userData,
      newPassword: userData?.newPassword
        ? Buffer.from(userData.newPassword.trim(), 'utf8').toString('base64')
        : undefined,
      oldPassword: userData?.oldPassword
        ? Buffer.from(userData.oldPassword.trim(), 'utf8').toString('base64')
        : undefined,
    };
    return { updateObject, accountId };
  }

  static getForgotPasswordPayload(email: string): ForgotPasswordPayload {
    return { userEmail: email };
  }
}
