import React, { useCallback, useEffect, useState } from 'react';
import {
  AvailableIcons,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import {
  AIPromoTagSneakPickWrapper,
  AISneakPickContentStyled,
  BlueTextTypographyStyled,
  DisplayPreferencesButtonStyled,
  DisplayPreferencesButtonWrapperStyled,
} from './CatalogExplorerPageHeader.styles';
import { SneakPeak } from './SneakPick/SneakPick';

const HAS_SHOWN_AI_TAGS_PROMO = 'hasShownAITagsPromo';

const AIPromoTagSneakPick = () => {
  return (
    <SneakPeak>
      <AISneakPickContentStyled>
        <BlueTextTypographyStyled type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
          NEW! &nbsp;
        </BlueTextTypographyStyled>
        <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
          Customize columns
        </Typography>
      </AISneakPickContentStyled>
    </SneakPeak>
  );
};

/**
 * DisplayPreferencesButtonWithPromo:
 * Will show conditionally promotion to AI tags for all users.
 * - The indication currently saved in sessionStorage (we don't want to keep it in localStorage)
 * - The promo sneak pick will be removed in the future once P.M will want to remove it.
 */
export const DisplayPreferencesButtonWithPromo = ({
  onDisplayPrefButtonClick,
}: {
  onDisplayPrefButtonClick: () => void;
}) => {
  const [showAITagsPromo, setShowAITagsPromo] = useState(false);

  useEffect(() => {
    const sessionStorageValue = sessionStorage.getItem(HAS_SHOWN_AI_TAGS_PROMO);

    if (!sessionStorageValue) {
      setShowAITagsPromo(true);
    }
  }, []);

  const handleDisplayPrefButtonClick = useCallback(() => {
    onDisplayPrefButtonClick();

    setShowAITagsPromo(false);
    sessionStorage.setItem(HAS_SHOWN_AI_TAGS_PROMO, 'true');
  }, []);

  return (
    <DisplayPreferencesButtonWrapperStyled>
      <DisplayPreferencesButtonStyled
        name={AvailableIcons.Gear}
        onClick={handleDisplayPrefButtonClick}
      />
      {showAITagsPromo && (
        <AIPromoTagSneakPickWrapper>
          <AIPromoTagSneakPick />
        </AIPromoTagSneakPickWrapper>
      )}
    </DisplayPreferencesButtonWrapperStyled>
  );
};
