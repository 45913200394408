import { FieldType, ShopDataField } from 'src/services';
import { MANDATORY_CATALOG_ORDERED_DATA_FIELDS } from '../categoryExplorer.config';
import { DisplayedFieldItem } from './types';
import { v4 as uuid } from 'uuid';
import { DisplayFieldsByType } from '../types';

export const createFormItem = ({
  fieldType,
  value,
  text,
  index,
  types,
}: {
  fieldType: FieldType;
  value: string;
  text: string;
  index: number;
  types: ShopDataField['types'];
}): DisplayedFieldItem => {
  return {
    id: uuid(),
    fieldType,
    value,
    text,
    index,
    types,
  };
};

/**
 * Create mandatory data fields display items of type "catalog"
 */
const createCatalogMandatoryFields = (catalogDataFieldsMap: Record<string, ShopDataField>) => {
  const baseFormFieldsList = MANDATORY_CATALOG_ORDERED_DATA_FIELDS.map((dataFieldName, index) => {
    const { name, displayName, types } = catalogDataFieldsMap?.[dataFieldName] || {};

    const formItem = createFormItem({
      fieldType: FieldType.CatalogField,
      value: name,
      text: displayName,
      index,
      types,
    });

    return formItem;
  });

  return baseFormFieldsList;
};

export const createInitialPreferences = ({
  catalogDataFieldsMap,
}: {
  catalogDataFieldsMap: Record<string, ShopDataField>;
}): DisplayFieldsByType => {
  return {
    [FieldType.CatalogField]: createCatalogMandatoryFields(catalogDataFieldsMap),
    [FieldType.AiTag]: [],
  };
};
